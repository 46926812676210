<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <article class="card-body" v-if="language.id === 1"> <!-- ENG -->
            <p>
                English is not supported.
            </p>
        </article>

        <article class="card-body" v-if="language.id === 2"> <!-- PL -->
            <h4 class="card-title">
                1 z 10 - Quiz
            </h4>

            <div v-if="quiz.id === 0">
                <p>
                    Witaj w quizie kwalifikacyjnym konkursu "1 z 10 Original War" organizowanego przez Sand of Siberia Team na Discordzie.
                </p>

                <div class="card-body">
                    <div class="row">
                        <p>
                            Wypełnij poniższy formularz aby wziąć udział w quizie.
                        </p>

                        <b-card-body class="w-100">
                            <b-form v-on:submit="onSubmitQuizData" v-on:reset="onResetQuizData">
                                <div class="mt-2">
                                    <label for="username">Nazwa</label>
                                    <b-form-input
                                        id="username"
                                        v-model="quiz.username"
                                        placeholder="Nazwa"
                                    ></b-form-input>
                                </div>

                                <div class="mt-2">
                                    <label for="discord">Identyfikator profilu Discord</label>
                                    <b-form-input
                                        id="discord"
                                        v-model="quiz.discord"
                                        placeholder="Identyfikator profilu Discord"
                                    ></b-form-input>
                                </div>

                                <div class="mt-2">
                                    <label for="email">Email</label>
                                    <b-form-input
                                        id="email"
                                        v-model="quiz.email"
                                        placeholder="Email"
                                    ></b-form-input>
                                </div>

                                <div class="mt-2">
                                    <label for="email">Kod</label>
                                    <b-form-input
                                        id="code"
                                        v-model="quiz.code"
                                        placeholder="Kod"
                                    ></b-form-input>
                                </div>

                                <div class="w-100 mt-2">
                                    <b-button type="submit" :disabled="validateQuiz" variant="primary">
                                        Wyślij
                                    </b-button>

                                    <b-button type="reset" variant="danger">
                                        Wyczyść
                                    </b-button>
                                </div>
                            </b-form>
                        </b-card-body>
                    </div>
                </div>
            </div>
            <div v-else-if="!quizComplete">
                <p>
                    Odpowiedz na 10 pytań.
                </p>

                <span>
                    <span class="d-block">Pytanie {{ activeQuestion + 1 }} / {{ questions.length }}.</span>
                    <span class="d-block">Pozostały czas na odpowiedź: <strong>{{ timer }}s.</strong></span>
                </span>

                <div class="card-body pt-0 pb-0 mt-2 border-card">
                    <div class="row"
                         v-for="(q, i) in questions"
                         :key="i"
                         v-if="activeQuestion === i"
                    >
                        <b-card-body class="w-100">
                            <span class="h5">{{ q.description }}</span>
                            <b-form-group :v-slot="i">
                                <b-form-radio-group
                                    :id="'question-' + i"
                                    name="radio-sub-component"
                                    class="m-2"
                                >
                                    <b-form-radio class="d-block" v-on:change="setAnswer(i, 'A')" value="A">{{ q.q1 }}</b-form-radio>
                                    <b-form-radio class="d-block" v-on:change="setAnswer(i, 'B')" value="B">{{ q.q2 }}</b-form-radio>
                                    <b-form-radio class="d-block" v-on:change="setAnswer(i, 'C')" value="C">{{ q.q3 }}</b-form-radio>
                                    <b-form-radio class="d-block" v-on:change="setAnswer(i, 'D')" value="D">{{ q.q4 }}</b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </b-card-body>
                    </div>
                </div>
            </div>
            <div v-else>
                <p>
                    Dziękujemy za wypełnienie formularza. Oczekuj dalszych informacji na Discordzie.
                </p>
            </div>
        </article>

        <modal name="quizModal" draggable adaptive height="auto" class="always-top-1">
            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('quizModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        Nie można wysłać zgłoszenia
                    </div>

                    <p>
                        {{ message }}
                    </p>

                    <div class="wrapper text-center">
                        <b-button-group size="lg">
                            <b-button v-on:click="$modal.hide('quizModal')">
                                OK
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                timeout: null,
                message: '',
                quiz: {
                    id: 0,
                    username: '',
                    email: '',
                    discord: '',
                    code: '',
                },
                timer: 45,
                timerActive: false,
                activeQuestion: -1,
                quizComplete: false,
                questions: [
                    {
                        id: 1,
                        description: 'Opis',
                        q1: 'A',
                        q2: 'B',
                        q3: 'C',
                        q4: 'D',
                        valid: 'A',
                        answer: null,
                    },
                ]
            };
        },
        computed: {
            ...mapGetters({
                language: 'getLanguageData'
            }),
            validateQuiz: function() {
                return this.quiz.username.length === 0 || this.quiz.discord.length === 0 || this.quiz.email.length === 0 || this.quiz.code.length === 0;
            }
        },
        watch: {
            timer: {
                handler(value) {
                    if (value > 0) {
                        if (this.timerActive) {
                            this.timeout = setTimeout(() => {
                                this.timer--;
                            }, 1000);
                        }
                    } else {
                        this.submitQuestion();
                    }
                },
                immediate: true, // This ensures the watcher is triggered upon creation
            },
        },
        methods: {
            onSubmitQuizData: function(e) {
                e.preventDefault();
                e.stopPropagation();

                let self = this;
                let data = self.quiz;

                axios.post('/app/quiz/submit/', data).then(function(response) {
                    let data = response.data;

                    if (!data.success) {
                        self.openModal(data.code);
                    } else {
                        self.quiz.id = data.id;
                        self.getQuestions();
                    }
                });
            },
            getQuestions: function() {
                let self = this;

                axios.get('/app/quiz/get-questions').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.questions = [];

                        for (let question in data.questions) {
                            self.questions.push({
                                id:  data.questions[question].id,
                                description: data.questions[question].question,
                                q1: data.questions[question].A,
                                q2: data.questions[question].B,
                                q3: data.questions[question].C,
                                q4: data.questions[question].D,
                                valid: data.questions[question].correct,
                                answer: null
                            });
                        }

                        self.timerActive = true;
                        self.timer = 30;
                        self.activeQuestion = 0;
                    }
                });
            },
            onResetQuizData: function() {
                this.quiz = {};
            },
            openModal: function (code) {
                this.$modal.show('quizModal');
                this.message = 'Wystąpił błąd.';

                if (code === 1) {
                    this.message = 'Twoje zgłoszenie zostało już zarejestrowane. Nie możesz ponownie wypełnić quizu.';
                } else if (code === 2) {
                    this.message = 'Kod dostępu jest nieprawidłowy.';
                }
            },
            setAnswer: function(i, answer) {
                this.questions[i].answer = answer;
                this.submitQuestion(i);
            },
            submitQuestion: function (i) {
                clearTimeout(this.timeout);

                this.activeQuestion++;
                this.timer = 30;

                if (this.activeQuestion + 1 > this.questions.length) {
                    this.quizComplete = true;

                    let self = this;
                    let data = {
                        id: self.quiz.id,
                        questions: self.questions,
                    };

                    axios.post('/app/quiz/submit-points/', data).then(function(response) {});
                }
            }
        }
    }
</script>

