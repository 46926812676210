<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('issues.title') }}
            </h4>

            <p>
                {{ trans('issues.description') }}
            </p>

            <div class="row mt-2">
                <div class="col-sm-3 col-12">
                    <b-img :src="usLogo"
                           v-on:mouseover="usLogo = changeImage(0, true)"
                           v-on:mouseleave="usLogo = changeImage(0, false)"
                           v-on:click="openProject(1)"
                           class="clickable mx-auto d-block"
                           rounded
                           alt="US Logo"
                    />
                </div>

                <div class="col-sm-3 col-12">
                    <b-img :src="arLogo"
                           v-on:mouseover="arLogo = changeImage(1, true)"
                           v-on:mouseleave="arLogo = changeImage(1, false)"
                           v-on:click="openProject(2)"
                           class="clickable mx-auto d-block"
                           rounded
                           alt="AR Logo"
                    />
                </div>

                <div class="col-sm-3 col-12">
                    <b-img :src="ruLogo"
                           v-on:mouseover="ruLogo = changeImage(2, true)"
                           v-on:mouseleave="ruLogo = changeImage(2, false)"
                           v-on:click="openProject(3)"
                           class="clickable mx-auto d-block"
                           rounded
                           alt="RU Logo"
                    />
                </div>

                <div class="col-sm-3 col-12">
                    <b-img :src="skLogo"
                           v-on:mouseover="skLogo = changeImage(3, true)"
                           v-on:mouseleave="skLogo = changeImage(3, false)"
                           v-on:click="openProject(4)"
                           class="clickable mx-auto d-block"
                           rounded
                           alt="SK Logo"
                    />
                </div>
            </div>

            <div class="mt-2">
                <p class="text-center text-secondary">
                    {{ text }}
                </p>
            </div>
        </div>

        <div class="text-center w-100 mt-5 mb-5" v-if="!isLoaded">
            <b-spinner variant="secondary" label="Loading..."/>
        </div>

        <div class="w-100 mt-4" v-if="tasks.length > 0 && isLoaded">
            <h6 class="text-center">
                {{ trans('issues.latest') }}
            </h6>

            <b-list-group>
                <b-list-group-item v-for="(t, i) in tasks"
                                   :key="t.id"
                                   v-on:click="onClickTask(i)"
                                   class="clickable flex-column align-items-start"
                >
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">
                            <b-badge variant="primary" v-if="t.status === 0">
                                {{ trans('issues.show.new') }}
                            </b-badge>
                            <b-badge variant="secondary" v-if="t.status === 1">
                                {{ trans('issues.show.todo') }}
                            </b-badge>
                            <b-badge variant="warning" v-if="t.status === 2">
                                {{ trans('issues.show.inProgress') }}
                            </b-badge>
                            <b-badge variant="success" v-if="t.status === 3">
                                {{ trans('issues.show.fixed') }}
                            </b-badge>
                            <b-badge variant="dark" v-if="t.status === 4">
                                {{ trans('issues.show.notABug') }}
                            </b-badge>
                            <b-badge variant="light" v-if="t.status === 5">
                                {{ trans('issues.show.cantReproduce') }}
                            </b-badge>

                            {{ t.name }}
                        </h6>
                    </div>

                    <p class="mb-1">
                        {{ t.description | truncate(90, '...') }}
                    </p>

                    <small class="text-secondary">
                        {{ t.updated_at }}
                    </small>

                    <div class="float-right">
                        <b-img :src="t.flag" width="30" height="16" />
                    </div>
                </b-list-group-item>
            </b-list-group>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tasks: {},
                isLoaded: false,
                text: this.trans('issues.select'),
                usLogo: 'images/us-logo.png',
                arLogo: 'images/ar-logo.png',
                ruLogo: 'images/ru-logo.png',
                skLogo: 'images/sk-logo.png',
                images: [
                    {
                        'normal': 'images/us-logo.png',
                        'hover': 'images/us-logo-hover.png',
                    },
                    {
                        'normal': 'images/ar-logo.png',
                        'hover': 'images/ar-logo-hover.png',
                    },
                    {
                        'normal': 'images/ru-logo.png',
                        'hover': 'images/ru-logo-hover.png',
                    },
                    {
                        'normal': 'images/sk-logo.png',
                        'hover': 'images/sk-logo-hover.png',
                    }
                ],
                texts: [
                    this.trans('issues.us'),
                    this.trans('issues.ar'),
                    this.trans('issues.ru'),
                    this.trans('issues.sk'),
                ]
            }
        },
        beforeCreate: function() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            let self = this;

            axios.get('/app/issues/latest/get/').then(function(response) {
                let data = response.data;
                self.isLoaded = true;
                self.$store.commit('removeFromAppQueue');

                if (data.success) {
                    self.tasks = data.tasks;
                }
            });
        },
        methods: {
            changeImage: function(i, mode) {
                this.text = (mode) ? this.texts[i] : this.trans('issues.select');
                return (mode) ? this.images[i].hover : this.images[i].normal;
            },
            openProject: function(i) {
                this.$router.push('/issues/' + i);
            },
            onClickTask: function(id) {
                let task = this.tasks[id];

                this.$router.push('/issues/' + task.id_project + '/task/' + task.id);
            }
        }
    }
</script>
