<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <article class="card-body" v-if="language.id === 1"> <!-- Angielski -->
            <h4 class="card-title">
                Terms
            </h4>

            <p>
                These terms apply only to the sand-of-siberia.pl.

            </p>

            <div class="mt-5">
                <h5>
                    User's rights
                </h5>

                <div class="pl-2 text-justify">
                    User can:

                    <ul class="pl-3">
                        <li>
                            - Manage his personal data
                        </li>

                        <li>
                            - Request to change / delete all personal data (does not apply to user entries on the site)
                        </li>

                        <li>
                            - Request to delete  entries from other users who violate the rights or good name of the user
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Administrator's rights
                </h5>

                <div class="pl-2 text-justify">
                    Administrator can:

                    <ul class="pl-3">
                        <li>
                            - Delete any user entry without giving any reason
                        </li>

                        <li>
                            - Delete a user account without giving any reason
                        </li>

                        <li>
                            - Temporarily or permanently block a user account
                        </li>

                        <li>
                            - Manage user data only for website purpose
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    User's responsibilities
                </h5>

                <div class="pl-2 text-justify">
                    The User bears all responsibility for the content he publishes.
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Administrator's responsibility
                </h5>

                <div class="pl-2 text-justify">
                    The administrator is responsible for the security of users data and undertakes not to disclose such data to third parties.
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Copyright
                </h5>

                <div class="pl-2 text-justify">
                    All materials published on the website:

                    <ul class="pl-3">
                        <li>
                            - Belong to website author
                        </li>

                        <li>
                            - Or they were published with the prior consent of other authors
                        </li>

                        <li>
                            - Or they are materials publicly available on the web
                        </li>

                        <li>
                            - Or they are materials published by users on this website
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Cookies policy
                </h5>

                <div class="pl-2 text-justify">
                    The website uses "cookies" for the purposes of:

                    <ul class="pl-3">
                        <li>
                            - Statistical
                        </li>

                        <li>
                            - Functional
                        </li>

                        <li>
                            - Performance
                        </li>
                    </ul>

                    <p class="mt-1">
                        Cookie files are stored on the user's end device and can be deleted by him.
                    </p>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Recording activity on the website
                </h5>

                <div class="pl-2 text-justify">
                    All user activity on the site is recorded. The following data is stored:

                    <ul class="pl-3">
                        <li>
                            - IP address
                        </li>

                        <li>
                            - Browser used
                        </li>

                        <li>
                            - Activity Date
                        </li>
                    </ul>

                    <p class="mt-1">
                        The above data is not disclosed to third parties.
                    </p>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Newsletter
                </h5>

                <div class="pl-2 text-justify">
                    The newsletter service is voluntary. The user can opt out at any time.
                </div>
            </div>

            <div class="mt-5 text-center">
                <small>
                    The website administration reserves the right to make transactions involving the above selection at any time.
                </small>
            </div>
        </article>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                Regulamin
            </h4>

            <p>
                Niniejszy regulamin ma zastosowanie wyłącznie dla działalności serwisu sand-of-siberia.pl.
            </p>

            <div class="mt-5">
                <h5>
                    Prawa użytkownika
                </h5>

                <div class="pl-2 text-justify">
                    Użytkownik ma prawo:

                    <ul class="pl-3">
                        <li>
                            - Dowolnie zarządzać swoimi danymi personalnymi
                        </li>

                        <li>
                            - Zażądać zmiany/usunięcia wszelkich danych osobowych (nie dotyczy to wpisów zamieszczonych w serwisie)
                        </li>

                        <li>
                            - Zażądać usunięcia wpisów innych użytkowników naruszających prawa lub dobre imię danego użytkownika
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Prawa administratora
                </h5>

                <div class="pl-2 text-justify">
                    Administrator ma prawo:

                    <ul class="pl-3">
                        <li>
                            - Usunąć bez podania przyczyny wpis dowolnego użytkownika serwisu
                        </li>

                        <li>
                            - Usunąć bez podania przyczyny konto dowolnego użytkownika serwisu
                        </li>

                        <li>
                            - Nakładać tymczasowe lub stałe blokady na konto dowolnego użytkownika
                        </li>

                        <li>
                            - Zarządzać danymi użytkowników wyłącznie na potrzeby serwisu
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Odpowiedzialność użytkownika
                </h5>

                <div class="pl-2 text-justify">
                    Użytkownik ponosi wszelką odpowiedzialność za publikowane przez siebie treści.
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Odpowiedzialność administratora
                </h5>

                <div class="pl-2 text-justify">
                    Administrator odpowiada za bezpieczeństwo danych użytkowników oraz zobowiązuje się do nieprzekazywania tychże danych osobom trzecim.
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Prawa autorskie
                </h5>

                <div class="pl-2 text-justify">
                    Wszelkie materiały publikowane na łamach serwisu:

                    <ul class="pl-3">
                        <li>
                            - Należą do autora serwisu
                        </li>

                        <li>
                            - Lub zostały opublikowane po uprzednio uzyskanej zgodzie innych autorów
                        </li>

                        <li>
                            - Lub są to materiały publicznie dostępne w sieci
                        </li>

                        <li>
                            - Lub są to materiały opublikowane przez użytkowników serwisu na łamach tegoż serwisu
                        </li>
                    </ul>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Polityka ciasteczek
                </h5>

                <div class="pl-2 text-justify">
                    Serwis używa plików "cookies" w celach:

                    <ul class="pl-3">
                        <li>
                            - Statystycznych
                        </li>

                        <li>
                            - Funkcjonalnych
                        </li>

                        <li>
                            - Wydajnościowych
                        </li>
                    </ul>

                    <p class="mt-1">
                        Pliki ciasteczek przechowywane są na urządzeniu końcowym użytkownika i mogą być przez niego usunięte.
                    </p>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Rejestrowanie aktywności w serwisie
                </h5>

                <div class="pl-2 text-justify">
                    Wszelka aktywność użytkownika w zakresie serwisu jest rejestrowana. Przechowywane są następujące dane:

                    <ul class="pl-3">
                        <li>
                            - Adres IP
                        </li>

                        <li>
                            - Użyta przeglądarka
                        </li>

                        <li>
                            - Data aktywności
                        </li>
                    </ul>

                    <p class="mt-1">
                        Powyższe dane nie są udostępniane osobom trzecim.
                    </p>
                </div>
            </div>

            <div class="mt-5">
                <h5>
                    Newsletter
                </h5>

                <div class="pl-2 text-justify">
                    Usługa newsletter'a jest dobrowolna. Użytkownik może z niej zrezygnować w dowolnym momencie.
                </div>
            </div>

            <div class="mt-5 text-center">
                <small>
                    Administracja serwisu zastrzega sobie prawo do dokonywania modyfikacji powyższego regulaminu w dowolnym momencie.
                </small>
            </div>
        </article>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                language: 'getLanguageData'
            }),
        },
    }
</script>
