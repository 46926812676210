<template>
    <div>
        <div class="row">
            <!-- Nav Top Buttons -->
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin" v-on:click="onShowUser" :disabled="userSelected  === 0">
                        Wyświetl informacje
                    </b-button>

                    <b-button type="button" class="btn-admin" id="popover-target-1">
                        Legenda
                    </b-button>

                    <b-popover target="popover-target-1" triggers="hover" placement="top">
                        <template v-slot:title>
                            Oznaczenia tabeli
                        </template>

                        <div class="bg-danger font-weight-bold p-2 d-block">
                            Zbanowany użytkownik
                        </div>

                        <div class="bg-info font-weight-bold p-2 d-block">
                            Zaznaczony użytkownik
                        </div>

                        <div class="bg-warning font-weight-bold p-2 d-block">
                            Administrator
                        </div>
                    </b-popover>
                </div>
            </div>

            <!-- Data Table -->
            <div class="col-lg-12 col-md-8">
                <div class="table-responsive">
                    <div class="text-center m-5" v-if="!loaded">
                        <b-spinner variant="secondary"/>
                    </div>

                    <b-table dark striped hover sort-icon-left
                             :items="users"
                             :fields="usersFields"
                             :per-page="paginator.perPage"
                             :current-page="paginator.currentPage"
                             @row-clicked="onUserSelected"
                             id="usersTable"
                             :tbody-tr-class="rowClass"
                             v-else-if="users.length > 0">
                        <template v-slot:cell(avatar)="data">
                            <b-img v-if="data.item.avatar !== null"
                                   :src="data.item.avatar"
                                   width="42"
                                   height="50"
                            />
                            <span v-else>
                                -
                            </span>
                        </template>

                        <template v-slot:cell(type)="data">
                            <span v-if="data.item.type === 'admin'">
                                Administrator
                            </span>

                            <span v-if="data.item.type === 'default'">
                                Użytkownik
                            </span>
                        </template>
                    </b-table>

                    <p v-else-if="users.length === 0">
                        Brak rekordów.
                    </p>

                    <div class="mt-3">
                        <b-pagination class="paginator"
                                      v-model="paginator.currentPage"
                                      :total-rows="users.length"
                                      :per-page="paginator.perPage"
                                      aria-controls="usersTable"
                                      align="center"
                                      v-if="users.length > paginator.perPage">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>

        <modal name="userModal" draggable adaptive width="80%" height="auto" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('userModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        Profil użytkownika - {{ userData.name }}
                    </div>

                    <div class="w-100 row">
                        <div class="col-2">
                            <b-img :src="userData.avatar"
                                   fluid
                            />

                            <b-button class="mt-1 w-100 btn-admin" v-on:click="onRemoveAvatar" :disabled="userData.type === 'admin'">
                                Usuń awatar
                            </b-button>

                            <b-button class="mt-1 w-100 btn-admin" v-on:click="onBanUser(true)" v-if="userData.active !== -1" :disabled="userData.type === 'admin'">
                                Zbanuj
                            </b-button>

                            <b-button class="mt-1 w-100 btn-admin" v-on:click="onBanUser(false)" v-else :disabled="userData.type === 'admin'">
                                Odbanuj
                            </b-button>
                        </div>

                        <div class="col-5">
                            <b-card-group deck>
                                <b-card no-body header="Dane edytowalne">
                                    <b-list-group flush>
                                        <b-list-group-item class="clickable" v-on:click="onEditData('email', userData.email)">
                                            E-mail: {{ userData.email }}
                                        </b-list-group-item>

                                        <b-list-group-item class="clickable" v-on:click="onEditData('name', userData.name)">
                                            Nazwa: {{ userData.name }}
                                        </b-list-group-item>

                                        <b-list-group-item class="clickable" v-if="userData.about" v-on:click="onEditData('about', userData.about)">
                                            Opis: {{ userData.about | truncate(30, '...') }}
                                        </b-list-group-item>

                                        <b-list-group-item class="clickable" v-if="userData.localization" v-on:click="onEditData('localization', userData.localization)">
                                            Lokalizacja: {{ userData.localization }}
                                        </b-list-group-item>

                                        <b-list-group-item class="clickable" v-if="userData.fav_streamer" v-on:click="onEditData('fav_streamer', userData.fav_streamer)">
                                            Streamer: {{ userData.fav_streamer }}
                                        </b-list-group-item>

                                        <b-list-group-item class="clickable" v-if="userData.multiplayer_nickname" v-on:click="onEditData('multiplayer_nickname', userData.multiplayer_nickname)">
                                            Nick na multi: {{ userData.multiplayer_nickname }}
                                        </b-list-group-item>
                                    </b-list-group>

                                    <b-card-body class="bg-dark-blue-2">
                                        <b-form v-on:submit="onSubmitUserData" v-on:reset="onResetUserData">
                                            <b-form-textarea
                                                id="textarea"
                                                v-model="userDataEdit.value"
                                                rows="3"
                                                max-rows="6"
                                            ></b-form-textarea>

                                            <div class="w-100 mt-2">
                                                <b-button type="submit" variant="primary" :disabled="userData.type === 'admin'">
                                                    Zapisz
                                                </b-button>

                                                <b-button type="reset" variant="danger" :disabled="userData.type === 'admin'">
                                                    Reset
                                                </b-button>

                                                <span class="pl-2">
                                                    Edytujesz: {{ userDataEdit.key }}
                                                </span>
                                            </div>
                                        </b-form>
                                    </b-card-body>
                                </b-card>
                            </b-card-group>
                        </div>

                        <div class="col-5">
                            <b-card-group deck>
                                <b-card no-body header="Pozostałe dane">
                                    <b-list-group flush>
                                        <b-list-group-item v-if="userData.active === -1">
                                            Status: Zbanowany
                                        </b-list-group-item>

                                        <b-list-group-item v-else-if="userData.active === 1">
                                            Status: Aktywny
                                        </b-list-group-item>

                                        <b-list-group-item v-else>
                                            Status: Wymaga aktywacji
                                        </b-list-group-item>

                                        <b-list-group-item v-if="userData.last_login">
                                            Ostatnia wizyta: {{ userData.last_login.time }} | {{ userData.last_login.ip }}
                                        </b-list-group-item>

                                        <b-list-group-item v-if="userData.last_active">
                                            Ostatnia aktywność: {{ userData.last_active }}
                                        </b-list-group-item>

                                        <b-list-group-item v-if="userData.ip">
                                            IP: {{ userData.ip }}
                                        </b-list-group-item>

                                        <b-list-group-item v-if="userData.type === 'admin'">
                                            Rola: Administrator
                                        </b-list-group-item>

                                        <b-list-group-item v-else>
                                            Rola: Użytkownik
                                        </b-list-group-item>

                                        <b-list-group-item>
                                            Zarejestrowany: {{ userData.created_at }}
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </b-card-group>
                        </div>
                    </div>

                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                paginator: {
                    currentPage: 1,
                    perPage: 10,
                },
                users: [],
                userData: {
                    id: '',
                    email: '',
                    name: '',
                    avatar: '',
                    last_active: '',
                    player_since: '',
                    website: '',
                    localization: '',
                    about: '',
                    multiplayer_nickname: '',
                    fav_streamer: '',
                    created_at: '',
                    type: '',
                    active: 0,
                    ip: '',
                    last_login: {
                        time: '',
                        ip: '',
                        rememberMe: false,
                    },
                },
                userDataEdit: {},
                usersFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: '#',
                    },
                    {
                        key: 'email',
                        label: 'E-Mail',
                    },
                    {
                        key: 'name',
                        label: 'Nazwa',
                        sortable: true,
                    },
                    {
                        key: 'type',
                        label: 'Rola',
                        sortable: true,
                    },
                    {
                        key: 'avatar',
                        label: 'Awatar',
                    },
                    {
                        key: 'created_at',
                        label: 'Data rejestracji',
                    }
                ],
                userSelected: 0,
                loaded: false,
            };
        },
        created() {
            this.loadUserData();
        },
        methods: {
            rowClass(item, type) {
                if (!item || type !== 'row')
                    return 'clickable';
                if (item.id === this.userSelected)
                    return 'clickable table-info';
                if (item.active === -1) // banned
                    return 'clickable table-danger';
                if (item.type === 'admin')
                    return 'clickable table-warning';

                return 'clickable';
            },
            onUserSelected: function(row) {
                let id = this.userSelected;

                if (id !== row.id)
                    this.userSelected = row.id;
                else
                    this.userSelected = 0;
            },
            loadUserData: function() {
                let self = this;

                axios.get('/admin/users/get/').then(function(response) {
                    self.loaded = true;
                    self.users = response.data.users;
                });
            },
            onShowUser: function() {
                let id = this.userSelected;

                if (!id)
                    return;

                let self = this;

                axios.get('/admin/users/get/' + parseInt(id)).then(function(response) {
                    if (response.data.success) {
                        self.userData = response.data.user;
                        self.userDataEdit = {};
                        self.$modal.show('userModal');
                    }
                });
            },
            onEditData: function(key, value) {
                if (!key || !value) {
                    return;
                }

                this.userDataEdit = {
                    'key' : key,
                    'value' : value,
                };
            },
            onSubmitUserData: function(e) {
                e.preventDefault();
                e.stopPropagation();

                let self = this;
                let data = this.userDataEdit;

                data.id = self.userSelected;

                axios.post('/admin/users/edit/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.$modal.hide('userModal');
                        self.loadUserData();
                    }
                });
            },
            onResetUserData: function() {
                this.userDataEdit = {};
            },
            onRemoveAvatar: function() {
                let self = this;
                let data = {
                    'id' : self.userData.id,
                };

                axios.post('/admin/users/avatar/', data).then(function(response) {
                    let result = response.data.result;

                    self.userData.avatar = result;

                    for (let i = 0; i < self.users.length; i++) {
                        if (self.users[i].id === self.userSelected) {
                            self.users[i].avatar = result;
                            break;
                        }
                    }
                });
            },
            onBanUser: function(mode) {
                let self = this;
                let data = {
                    'id' : self.userData.id,
                    'mode' : mode,
                };

                axios.post('/admin/users/ban/', data).then(function(response) {
                    let result = response.data.result;

                    self.userData.active = result;

                    for (let i = 0; i < self.users.length; i++) {
                        if (self.users[i].id === self.userSelected) {
                            self.users[i].active = result;
                            break;
                        }
                    }
                });
            }
        }
    }
</script>
