<template>
    <div>
        <div class="row">
            <!-- Nav Top Buttons -->
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin" v-on:click="$modal.show('swiperModal')">
                        Dodaj pozycję
                    </b-button>
                </div>
            </div>
        </div>

        <!-- Data Table -->
        <div class="row">
            <div class="col">
                <div class="table-responsive">
                    <div class="text-center m-5" v-if="!loaded">
                        <b-spinner variant="secondary"/>
                    </div>

                    <b-table dark striped hover sort-icon-left
                             tbody-tr-class="clickable"
                             :items="swiper.list"
                             :fields="swiper.table.fields"
                             :per-page="swiper.paginator.perPage"
                             :current-page="swiper.paginator.currentPage"
                             @row-clicked="onEditSwiper"
                             id="swiperTable"
                             v-else-if="swiper.list.length > 0">
                    </b-table>

                    <p v-else>
                        Brak rekordów.
                    </p>

                    <div class="mt-3">
                        <b-pagination class="paginator"
                                      v-model="swiper.paginator.currentPage"
                                      :total-rows="swiper.list.length"
                                      :per-page="swiper.paginator.perPage"
                                      aria-controls="swiperTable"
                                      align="center"
                                      v-if="swiper.list.length > swiper.paginator.perPage">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>

        <modal name="swiperModal" scrollable adaptive height="auto" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('swiperModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-icon mr-2" v-on:click="onFormResetSwiper()">
                        <i class="fa fa-trash"/>
                    </div>

                    <div class="modal-title">
                        Slider
                    </div>

                    <b-form id="swiperForm">

                        <b-form-group
                            id="fieldset-text"
                            description="Limit znaków: 40."
                            label="Tekst na swiperze [EN]"
                            label-for="input-text"
                            :invalid-feedback="textInvalid"
                            :state="textState"
                        >
                            <b-form-input id="input-text"
                                          v-model="swiper.text"
                                          :state="textState"
                                          trim />
                        </b-form-group>

                        <b-form-group
                            id="fieldset-text-pl"
                            description="Limit znaków: 40."
                            label="Tekst na swiperze [PL]"
                            label-for="input-text"
                            :invalid-feedback="textPLInvalid"
                            :state="textPLState"
                        >
                            <b-form-input id="input-text"
                                          v-model="swiper.text_pl"
                                          :state="textPLState"
                                          trim />
                        </b-form-group>

                        <b-form-group
                            id="fieldset-url"
                            description="Adres na który ma zostać przekierowany użytkownik."
                            label="URL"
                            label-for="input-url"
                        >
                            <b-form-input id="input-url"
                                          v-model="swiper.url"
                                          trim />
                        </b-form-group>

                        <b-form-group
                            id="fieldset-image-url"
                            label="Tło na sliderze"
                            label-for="input-image-url"
                        >
                            <b-form-select v-model="swiper.image_url"
                                           :options="images"
                                           id="input-image-url"
                                           class="mb-3" />
                            <b-img :src="swiper.image_url" fluid />
                        </b-form-group>


                        <b-button class="btn-admin btn-block mt-2"
                                  size="lg"
                                  v-on:click="onSubmitSwiper()"
                                  :disabled="canSubmitSwiper">
                            <i class="fa fa-save"/> Zapisz
                        </b-button>
                    </b-form>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loaded: false,
                swiper: {
                    id: 0,
                    text: '',
                    text_pl: '',
                    url: '',
                    image_url: '',
                    list: {},
                    table: {
                        fields: [
                            {
                                key: 'id',
                                label: 'ID',
                                sortable: true,
                            },
                            {
                                key: 'text',
                                label: 'Tekst (EN)',
                            },
                            {
                                key: 'text_pl',
                                label: 'Tekst (PL)',
                            },
                            {
                                key: 'url',
                                label: 'URL',
                            },
                            {
                                key: 'image_url',
                                label: 'Obraz',
                            }
                        ],
                    },
                    paginator: {
                        perPage: 10,
                        currentPage: 1,
                    }
                },
                images: [],
            }
        },
        computed: {
            canSubmitSwiper() {
                return (this.swiper.text.length * this.swiper.image_url.length * this.swiper.url.length) === 0 || this.textInvalid;
            },
            textState() {
                let text = this.swiper.text.length;
                return text > 0 && text <= 40;
            },
            textPLState() {
                let text = this.swiper.text_pl.length;
                return text > 0 && text <= 40;
            },
            textInvalid() {
                let text = this.swiper.text.length;

                if (text === 0)
                    return 'Wpisz tekst.';
                if (text > 40)
                    return 'Limit wynosi 40 znaków.';
            },
            textPLInvalid() {
                let text = this.swiper.text_pl.length;

                if (text === 0)
                    return 'Wpisz tekst.';
                if (text > 40)
                    return 'Limit wynosi 40 znaków.';
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData: function() {
                let self = this;

                axios.get('/admin/swiper/get/?admin=1').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        if (data.images.length) {
                            for (let i = 0; i < data.images.length; i++) {
                                self.images[i] = {
                                    'text' : data.images[i].name,
                                    'value' : data.images[i].url,
                                };

                                if (i === 0) {
                                    self.swiper.image_url = data.images[0].url;
                                }
                            }
                        }

                        self.swiper.list = data.swiper;
                        self.loaded = true;
                    }
                });
            },
            onEditSwiper: function(swiper) {
                this.swiper.id = swiper.id;
                this.swiper.text = swiper.text;
                this.swiper.text_pl = swiper.text_pl;
                this.swiper.url = swiper.url;
                this.swiper.image_url = swiper.image_url;

                this.$modal.show('swiperModal');
            },
            onResetSwiper: function() {
                this.swiper.id = 0;
                this.swiper.text = '';
                this.swiper.url = '';
                this.swiper.image_url = '';
            },
            onSubmitSwiper: function() {
                let self = this;
                let data = {
                    'text' : this.swiper.text,
                    'text_pl' : this.swiper.text_pl,
                    'url' : this.swiper.url,
                    'image_url' : this.swiper.image_url,
                };

                if (this.swiper.id > 0)
                    data.id = this.swiper.id;

                axios.post('/admin/swiper/save/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.$modal.hide('swiperModal');
                        self.onResetSwiper();
                        self.getData();
                    }
                });
            }
        }
    }
</script>
