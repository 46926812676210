<template>
    <div class="row">

        <!-- Nav Top Buttons -->
        <div class="col-lg-9 col-md-8">
            <div class="btn-group btn-group-admin">
                <b-button type="button" v-on:click="showDownloadForm()" class="btn-admin">
                    Dodaj plik
                </b-button>
                <b-button type="button" v-on:click="setVisibilityModalCategories(true)" class="btn-admin">
                    Kategorie plików
                </b-button>
                <b-button type="button" v-on:click="setVisibilityModalHistory(true)" class="btn-admin">
                    Historia <span class="badge badge-light">{{ historyCount }}</span>
                </b-button>
            </div>
        </div>

        <!-- Data Table -->
        <div class="col-lg-12 col-md-8">
            <div class="table-responsive">
                <div class="text-center m-5" v-if="!tableDataLoaded">
                    <b-spinner variant="secondary"/>
                </div>

                <b-table dark striped hover sort-icon-left
                         :items="downloadFiles"
                         :fields="tableFields"
                         :per-page="tablePaginationPerPage"
                         :current-page="tablePaginationCurrentPage"
                         @row-clicked="editDownloadForm"
                         id="downloadTable"
                         v-else-if="downloadFiles.length > 0">
                </b-table>

                <p v-else-if="downloadFiles.length === 0">Brak rekordów.</p>

                <div class="mt-3">
                    <b-pagination class="paginator"
                                  v-model="tablePaginationCurrentPage"
                                  :total-rows="downloadFiles.length"
                                  :per-page="tablePaginationPerPage"
                                  aria-controls="downloadTable"
                                  align="center"
                                  v-if="downloadFiles.length > tablePaginationPerPage">
                    </b-pagination>
                </div>
            </div>
        </div>

        <!-- Modal Window -->
        <modal name="categoriesModal" draggable adaptive height="auto" :width="768" class="always-top-2">
            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('categoriesModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        Kategorie plików
                    </div>

                    <b-table dark striped hover sort-icon-left
                             :items="categories"
                             :fields="tableCategoryFields"
                             @row-clicked="editCategoryForm"
                             v-if="categories.length > 0">

                        <template v-slot:cell(name1)="data">
                            {{ data.item.names[0].name }}
                        </template>

                        <template v-slot:cell(name2)="data">
                            {{ data.item.names[1].name }}
                        </template>
                    </b-table>

                    <p v-if="categories.length === 0">Brak rekordów.</p>

                    <b-form v-on:submit="submitCategoryForm" ref="categoryForm" class="mt-5">
                        <div class="row">
                            <div class="col">
                                <b-form-group
                                    id="input-group-new-category-1"
                                    label="Nazwa kategorii"
                                    label-for="input-new-category-1"
                                    description="Nowa kategoria która będzie wyświetlana w zakładce Download (eng)."
                                >
                                    <b-form-input
                                        id="input-new-category"
                                        v-model="categoryForm.names[0]"
                                        type="text"
                                        required
                                        placeholder="Podaj nazwę kategorii"
                                    />
                                </b-form-group>
                            </div>

                            <div class="col">
                                <b-form-group
                                    id="input-group-new-category-2"
                                    label="Nazwa kategorii"
                                    label-for="input-new-category-2"
                                    description="Nowa kategoria która będzie wyświetlana w zakładce Download (pl)."
                                >
                                    <b-form-input
                                        id="input-new-category"
                                        v-model="categoryForm.names[1]"
                                        type="text"
                                        required
                                        placeholder="Podaj nazwę kategorii"
                                    />
                                </b-form-group>
                            </div>
                        </div>

                        <b-form-group
                            id="input-group-new-order-category"
                            label="Kolejność"
                            label-for="input-group-new-order-category"
                            description="Kolejność wyświetlania - im wyższa wartość tym wyżej będzie wyświetlana."
                        >
                            <b-form-select v-model="categoryForm.order"
                                           :options="categoryForm.categoriesOrder"
                                           id="input-group-new-order-category"/>
                        </b-form-group>

                        <formError v-bind:error="categoryForm.error"/>

                        <div class="text-center">
                            <b-button-group>
                                <b-button type="button" variant="danger" v-if="categoryForm.mode === 'edit'" v-on:click="deleteCategoryForm">
                                    <i class="fa fa-trash-o"/> Usuń</b-button>
                                <b-button type="reset" variant="info"><i class="fa fa-times"/> Wyczyść</b-button>
                                <b-button type="submit" variant="success"><i class="fa fa-check"/> Zapisz</b-button>
                            </b-button-group>
                        </div>
                    </b-form>
                </div>
            </div>
        </modal>

        <!-- Modal Window -->
        <div v-show="showModalDownload">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper" v-on:click="setVisibilityModalDownload(false)">
                        <div class="modal-container">
                            <div class="card-body">
                                <div class="row justify-content-md-center">
                                    <div class="card bg-dark-blue-2 border-card col-md-6 modal-scrollable" v-on:click.stop="">
                                        <article class="card-body">
                                            <h3>Formularz pliku</h3>

                                            <b-form v-on:submit="submitDownloadForm" id="downloadForm" ref="downloadForm">
                                                <div class="form-group">
                                                    <b-form-group
                                                        id="input-filename-group"
                                                        label="Nazwa"
                                                        label-for="fileNameInput"
                                                        description="Wyświetlana nazwa - bez rozszerzenia."
                                                    >
                                                        <b-form-input
                                                            id="fileNameInput"
                                                            v-model="downloadForm.fileName"
                                                            type="text"
                                                            required
                                                            maxlength="100"
                                                            placeholder="Sand of Siberia">
                                                        </b-form-input>
                                                    </b-form-group>

                                                    <b-form-group
                                                        id="input-description-groupEn"
                                                        label="Opis (eng)"
                                                        label-for="textAreaDescEn"
                                                    >
                                                        <b-form-textarea
                                                            id="textAreaDescEn"
                                                            rows="4"
                                                            required
                                                            maxlength="1200"
                                                            placeholder="Opis..."
                                                            v-model="downloadForm.description">
                                                        </b-form-textarea>
                                                    </b-form-group>

                                                    <b-form-group
                                                        id="input-description-groupPl"
                                                        label="Opis (pl)"
                                                        label-for="textAreaDescPl"
                                                    >
                                                        <b-form-textarea
                                                            id="textAreaDescPl"
                                                            rows="4"
                                                            required
                                                            maxlength="1200"
                                                            placeholder="Opis..."
                                                            v-model="downloadForm.description_pl">
                                                        </b-form-textarea>
                                                    </b-form-group>

                                                    <b-form-group
                                                        id="input-category-id"
                                                        label="Kategoria"
                                                        label-for="selectCategoryId"
                                                    >
                                                        <b-form-select v-model="downloadForm.categoryId"
                                                                       :options="categoryForm.categories"
                                                                       id="selectCategoryId">
                                                        </b-form-select>
                                                    </b-form-group>

                                                    <b-form-group
                                                        id="input-ver-group"
                                                        label="Wersja"
                                                        label-for="verReqInput"
                                                        description="Wymagana wersja OW - pole opcjonalne"
                                                    >
                                                        <b-form-input
                                                            id="verReqInput"
                                                            v-model="downloadForm.verReq"
                                                            type="text"
                                                            maxlength="100"
                                                            placeholder="2.3.0">
                                                        </b-form-input>
                                                    </b-form-group>

                                                    <b-form-group>
                                                        <b-form-checkbox
                                                            id="checkboxStatus"
                                                            v-model="downloadForm.fileStatus"
                                                            value="1"
                                                            unchecked-value="0"
                                                        >
                                                            Plik publiczny
                                                        </b-form-checkbox>
                                                    </b-form-group>

                                                    <b-form-group
                                                        id="input-files-group"
                                                        label="Plik zdalny"
                                                        label-for="selectFilesList"
                                                    >
                                                        <b-form-select v-model="downloadForm.selectedFile" id="selectFilesList">
                                                            <option v-for="(file, index) in remoteFiles">
                                                                {{ file }}
                                                            </option>
                                                        </b-form-select>
                                                    </b-form-group>

                                                </div>

                                                <div class="form-upload-1">
                                                    <table class="table table-striped table-dark table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Maksymalny rozmiar</th>
                                                                <th scope="col">Dopuszczalne typy</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="col">{{ fileSizeMB }} MB</th>
                                                                <th scope="col">{{ getAllowedFileExtension(fileType) }}</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table class="table table-striped table-dark table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Nazwa</th>
                                                                <th scope="col">Wielkość</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Postęp</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="files.length === 0">
                                                                <th scope="col">---</th>
                                                                <th scope="col">---</th>
                                                                <th scope="col">---</th>
                                                                <th scope="col">---</th>
                                                            </tr>
                                                            <tr v-else v-for="(file, index) in files" :key="file.id" v-on:click="remove(file)">
                                                                <th scope="row">{{ file.name }}</th>
                                                                <td>{{ convertFileSize(file.size) }}</td>
                                                                <td v-if="file.error">{{ getUploadError(file.error) }}</td>
                                                                <td v-else-if="file.active">Plik jest dodawany</td>
                                                                <td v-else-if="!file.uploaded">Plik gotowy do dodania</td>
                                                                <td v-else>Plik znajduje się na serwerze</td>
                                                                <td>
                                                                    {{ file.progress }}% <span v-if="file.speed > 0">({{ convertFileSize(file.speed) }}/s)</span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <formError v-bind:error="downloadForm.error"/>

                                                    <div class="form-upload-2 text-center">
                                                        <div class="btn-group">
                                                            <button type="button" v-if="downloadForm.mode === 'edit'" v-on:click="deleteDownloadForm" class="btn btn-danger">
                                                                <i class="fa fa-trash-o"/> Usuń
                                                            </button>

                                                            <b-button type="reset" variant="info"><i
                                                                class="fa fa-times"/> Wyczyść</b-button>

                                                            <b-form v-on:submit="submitUploadForm" id="uploadForm">
                                                                <file-upload
                                                                    class="btn btn-primary btn-square"
                                                                    :headers="headers"
                                                                    :post-action="urlUpload"
                                                                    :extensions="fileType"
                                                                    :accept="fileTypeMIME"
                                                                    :multiple="multiple"
                                                                    :size="fileSize"
                                                                    v-model="files"
                                                                    @input-filter="inputFilter"
                                                                    @input-file="inputFile"
                                                                    ref="upload">
                                                                    <i class="fa fa-plus"/> Wybierz plik lokalny
                                                                </file-upload>

                                                                <button type="submit" class="btn btn-info" v-if="(!$refs.upload || !$refs.upload.active) && !uploaded" v-show="files.length">
                                                                    <i class="fa fa-arrow-up" aria-hidden="true"></i> Dodaj plik
                                                                </button>

                                                                <button type="button" class="btn btn-danger"  v-else-if="!uploaded" @click.prevent="$refs.upload.active = false">
                                                                    <span class="spinner-border spinner-border-sm"></span> Zatrzymaj
                                                                </button>
                                                            </b-form>

                                                            <button type="submit" class="btn btn-success float-right">
                                                                <i class="fa fa-check"></i> Zapisz
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </b-form>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <!-- Modal Window -->
        <div v-show="showModalHistory">
            <transition name="modal">
                <div class="modal-mask">
                    <div class="modal-wrapper" v-on:click="setVisibilityModalHistory(false)">
                        <div class="modal-container">
                            <div class="card-body">
                                <div class="row justify-content-md-center">
                                    <div class="card bg-dark-blue-2 border-card col-md-6 modal-scrollable" v-on:click.stop="">
                                        <article class="card-body">
                                            <h3>Logi</h3>
                                            <b-form v-on:submit="submitHistoryForm" id="historyForm" ref="historyForm">
                                                <div class="input-group mb-3">
                                                    <b-form-select v-model="historyModeSelected"
                                                                   :options="historyModes"/>
                                                    <div class="input-group-append">
                                                        <b-button variant="primary" type="submit"><i
                                                            class="fa fa-search"/> Szukaj</b-button>
                                                    </div>
                                                </div>
                                            </b-form>

                                            <b-table dark striped hover sort-icon-left
                                                     :items="historyData"
                                                     :fields="tableHistoryFields"
                                                     :current-page="tableHistoryCurrentPage"
                                                     :per-page="tablePaginationPerPage"
                                                     v-if="historyData.length > 0">
                                            </b-table>

                                            <div class="mt-3">
                                                <b-pagination class="paginator"
                                                              v-model="tableHistoryCurrentPage"
                                                              :total-rows="historyData.length"
                                                              :per-page="tablePaginationPerPage"
                                                              aria-controls="historyTable"
                                                              align="center"
                                                              v-if="historyData.length > tablePaginationPerPage">
                                                </b-pagination>
                                            </div>

                                            <p v-if="historyData.length === 0">
                                                Brak wyników.
                                            </p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                // modal
                showModalDownload: false,
                showModalCategories: false,
                showModalHistory: false,

                // form download
                files: [],
                fileSizeMB: 0,
                fileSize: 0,
                fileType: 'zip,rar,7zip',
                fileTypeMIME: 'application/x-rar-compressed,application/octet-stream,application/zip,application/octet-stream,application/x-zip-compressed,multipart/x-zip',
                urlUpload: '/admin/download/upload',
                multiple: false,
                headers: {
                    'X-Csrf-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                },
                uploaded: false,
                remoteFiles: [],
                downloadFiles: [],
                downloadForm: {
                    id: 0,
                    fileName: '',
                    fileStatus: 0,
                    verReq: '',
                    selectedFile: '',
                    categoryId: 0,
                    description: '',
                    description_pl: '',
                    mode: 'new', // new, edit
                    error: null
                },

                // form category
                categories: [],
                categoryForm: {
                    categoriesOrder: {},
                    categories: {},
                    order: 0,
                    names: {},
                    mode: 'new',
                    error: null
                },
                // table category
                tableCategoryFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: '#',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'name1',
                        label: 'Nazwa (eng)',
                    },
                    {
                        key: 'name2',
                        label: 'Nazwa (pl)',
                    },
                    {
                        key: 'order',
                        sortable: true,
                        label: 'Kolejność',
                        tdClass: 'clickable'
                    }
                ],

                // table main
                tableDataLoaded: false,
                tablePaginationCurrentPage: 1,
                tablePaginationPerPage: 10,
                tableFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: '#',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'name',
                        sortable: true,
                        label: 'Nazwa',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'file',
                        sortable: true,
                        label: 'Nazwa pliku',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'size',
                        sortable: false,
                        label: 'Wielkość',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'counter',
                        sortable: true,
                        label: 'Licznik pobrań',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'status',
                        sortable: true,
                        label: 'Widoczność',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'ver_required',
                        sortable: false,
                        label: 'Wymagana wersja',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'created_at',
                        sortable: true,
                        label: 'Data utworzenia',
                        tdClass: 'clickable'
                    },
                    {
                        key: 'updated_at',
                        sortable: true,
                        label: 'Data modyfikacji',
                        tdClass: 'clickable'
                    }
                ],

                // history
                historyCount: 0,
                historyData: [],
                historyModeSelected: 'today',
                historyModes: [],
                tableHistoryFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: '#'
                    },
                    {
                        key: 'text',
                        sortable: false,
                        label: 'Treść'
                    },
                    {
                        key: 'id_download',
                        sortable: true,
                        label: 'Id pliku'
                    },
                    {
                        key: 'filename',
                        sortable: false,
                        label: 'Nazwa pliku'
                    }
                ],
                tableHistoryCurrentPage: 1,
            }
        },
        created: function() {
            this.init();
        },
        methods: {
            init: function() {
                let self = this;

                axios.get('/admin/download/init').then(function(response) {
                    let data = response.data;

                    self.fileSize = data.uploadMaxFilesize;
                    self.fileSizeMB = data.uploadMaxFilesizeMb;
                    self.remoteFiles = data.remoteFilesList;
                    self.downloadFiles = data.downloadFilesList;
                    self.categories = data.categoriesList;
                    self.historyCount = data.historyLogCount;
                    self.historyModes = data.historyModes;

                    self.tableDataLoaded = true;

                    self.getCategoriesOrder();
                }, function() {

                });
            },
            update: function() {
                let self = this;

                axios.get('/admin/download/update').then(function(response) {
                    let data = response.data;

                    self.remoteFiles = data.remoteFilesList;
                    self.downloadFiles = data.downloadFilesList;
                    self.categories = data.categoriesList;
                    self.historyCount = data.historyLogCount;

                    self.resetCategoryForm();
                    self.getCategoriesOrder();
                }, function() {

                });
            },
            remove: function(file) {
                this.$refs.upload.remove(file);
                this.uploaded = false;
            },
            setVisibilityModalDownload: function(value) {
                this.showModalDownload = value;
            },
            setVisibilityModalCategories: function(value) {
                if (value) {
                    this.$modal.show('categoriesModal');
                    this.resetCategoryForm();
                } else {
                    this.$modal.hide('categoriesModal');
                }
            },
            setVisibilityModalHistory: function(value) {
                this.showModalHistory = value;
            },
            getUploadError: function(message) {
                if (message === 'extension') {
                    return 'Niedozwolone rozszerzenie';
                }

                if (message === 'denied') {
                    return 'Odmowa dostępu';
                }

                if (message === 'size') {
                    return 'Przekroczono dopuszczalny rozmiar';
                }

                if (message === 'timeout') {
                    return 'Przekroczono limit czasu połączenia';
                }

                if (message === 'network') {
                    return 'Wystąpił błąd sieci';
                }

                if (message === 'server') {
                    return 'Wystąpił błąd serwera';
                }

                return message;
            },
            getAllowedFileExtension: function(ext)
            {
                return '.' + ext.replace(/,/g, ' .');
            },
            inputFilter: function(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // before upload
                }
            },
            inputFile: function(newFile, oldFile) {
                if (typeof(newFile) === 'undefined') {
                    return;
                }
                // added
                if (newFile && !oldFile) {
                    this.uploaded = false;
                }
                // uploaded
                if (newFile && oldFile) {
                    this.uploaded = false;
                }
                // removed
                if (!newFile && oldFile) {
                    this.uploaded = false;
                }
                // Uploaded successfully
                if (newFile.success) {
                    newFile.uploaded = true;
                    this.uploaded = true;
                    this.update();
                }
            },
            getCategoriesOrder: function() {
                this.categoryForm.categoriesOrder = {};
                this.categoryForm.categories = {};

                let length = this.categories.length;

                for (let i = 0; i <= length; i++) {
                    this.categoryForm.categoriesOrder[i] = i;

                    if (i === length) {
                        continue;
                    }

                    this.categoryForm.categories[i] = {
                            value: this.categories[i].id,
                            text: this.categories[i].names[1].name,
                        };
                }

                if (length > 0) {
                    this.downloadForm.categoryId = this.categoryForm.categories[0];
                }
            },
            showDownloadForm: function() {
                this.setVisibilityModalDownload(true);
                this.resetDownloadForm();
            },
            editDownloadForm: function(row) {
                this.setVisibilityModalDownload(true);
                this.resetDownloadForm();

                this.downloadForm = {
                    error: null,
                    mode : 'edit',
                    id : row.id,
                    fileName : row.name,
                    description : row.description,
                    description_pl : row.description_pl,
                    verReq : row.ver_required,
                    fileStatus : row.status,
                    selectedFile : row.file,
                    categoryId: row.id_category
                };
            },
            deleteDownloadForm: function() {
                let id = this.downloadForm.id;

                if (!id)
                    return;

                let data = {
                    'id' : id
                };

                let self = this;

                axios.post('/admin/download/delete', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.setVisibilityModalDownload(false);
                        self.update();
                    } else {
                        self.downloadForm.error = data.message;
                    }
                }).catch(error => {
                    if (error.response) {
                        let data = error.response.data;

                        if (error.response.status === 500) {
                            self.downloadForm.error = 'Fatal error';
                        }
                    }
                });
            },
            submitDownloadForm: function(e) {
                e.preventDefault();

                this.error = '';

                let data = {
                    'name': this.downloadForm.fileName,
                    'ver_required': this.downloadForm.verReq,
                    'description': this.downloadForm.description,
                    'description_pl': this.downloadForm.description_pl,
                    'status': this.downloadForm.fileStatus,
                    'file': this.downloadForm.selectedFile,
                    'id_category' : this.downloadForm.categoryId
                };

                if (this.downloadForm.id > 0) {
                    data['id'] = this.downloadForm.id;
                }

                let self = this;

                axios.post('/admin/download/save', data).then(function (response) {
                    let data = response.data;

                    if (data.success) {
                        self.setVisibilityModalDownload(false);
                        self.update();
                    } else {
                        self.downloadForm.error = data.message;
                    }
                }).catch(error => {
                    if (error.response) {
                        let data = error.response.data;

                        if (error.response.status === 500) {
                            self.downloadForm.error = 'Fatal error';
                        }
                    }
                });
            },
            submitUploadForm: function(e) {
                e.preventDefault();

                // start upload
                this.$refs.upload.active = true;
            },
            resetDownloadForm: function() {
                this.downloadForm = {
                    error: null,
                    mode: 'new',
                    id: 0,
                    fileName: '',
                    description: '',
                    description_pl: '',
                    verReq: '',
                    fileStatus: 0,
                    selectedFile: '',
                    categoryId: 0
                };

                this.$refs.downloadForm.reset();
            },
            resetCategoryForm: function() {
                this.categoryForm.mode = 'new';

                this.categoryForm.id = 0;
                this.categoryForm.names = {};
                this.categoryForm.order = this.categoryForm.categoriesOrder[0];
                this.categoryForm.error = null;
            },
            editCategoryForm: function(row) {
                this.categoryForm.mode = 'edit';

                this.categoryForm.id = row.id;
                this.categoryForm.names = [
                    row.names[0].name,
                    row.names[1].name
                ],
                this.categoryForm.order = row.order;
            },
            deleteCategoryForm: function() {
                let id = this.categoryForm.id;

                if (!id)
                    return;

                let data = {
                    'id' : id
                };

                let self = this;

                axios.post('/admin/download/category/delete', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.update();
                    } else {
                        self.categoryForm.error = data.message;
                    }
                }).catch(error => {
                    if (error.response) {
                        let data = error.response.data;

                        if (error.response.status === 500) {
                            self.categoryForm.error = 'Fatal error';
                        }
                    }
                });
            },
            submitCategoryForm: function(e) {
                e.preventDefault();

                let data = {
                    'names' : this.categoryForm.names,
                    'order' : this.categoryForm.order
                };

                if (this.categoryForm.id > 0) {
                    data['id'] = this.categoryForm.id;
                }

                let self = this;

                axios.post('/admin/download/category/save', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.update();
                    } else {
                        self.categoryForm.error = data.message;
                    }
                }).catch(error => {
                    if (error.response) {
                        let data = error.response.data;

                        if (error.response.status === 500) {
                            self.categoryForm.error = 'Fatal error';
                        }
                    }
                });
            },
            submitHistoryForm: function(e) {
                e.preventDefault();

                let data = {
                    'mode' : this.historyModeSelected
                };

                let self = this;

                axios.post('/admin/download/history/log/', data).then(function(response) {
                    self.historyData = response.data;
                }).catch(error => {
                    if (error.response) {

                    }
                });
            }
        }
    }
</script>
