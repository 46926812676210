<template>
    <div class="row mt-5">

        <h5 class="text-center w-100">
            {{ trans('comments.comments') }}
        </h5>

        <div class="w-100 border-card"></div>

        <div class="card bg-dark-blue-2 border-none w-100" v-if="isLoaded">
            <div class="card-body" v-if="comments.length > 0" v-for="comment in comments.slice(this.paginator.perPage * (this.paginator.currentPage - 1), paginator.perPage * paginator.currentPage)">
                <div class="row">
                    <div class="col-md-2">
                        <b-img :src="comment.user.avatar"
                               class="img img-fluid d-none d-md-block img-nohover"
                               rounded
                        />
                    </div>
                    <div class="col-md-10">
                        <div>
                            <a class="float-left" :href="'/user/' + comment.id_author">
                                <strong>
                                    {{ comment.user.name }}
                                </strong>
                            </a>

                            <small class="text-secondary ml-3">
                                {{ comment.elapsed }}
                            </small>

                            <span class="float-right" v-if="userData.isLogged && userData.id === comment.id_author">
                                <i class="icon fa fa-edit" v-on:click="onEdit(comment)"/>
                                <i class="icon fa fa-remove" v-on:click="onRemove(comment)"/>
                            </span>
                        </div>

                        <div>
                            {{ comment.text }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-body" v-if="comments.length === 0">
                <p class="text-center">
                    {{ trans('comments.noComments') }}
                </p>
            </div>

            <div class="card-body" v-if="userData.isLogged">
                <div class="row">
                    <div class="col-md-2">
                        <b-img :src="userData.avatar"
                               class="img img-fluid d-none d-md-block img-nohover"
                               rounded
                        />
                    </div>
                    <div class="col-md-10">
                        <div>
                            <a class="float-left" :href="'/user/' + userData.id">
                                <strong>
                                    {{ userData.name }}
                                </strong>
                            </a>

                            <small class="text-secondary font-italic ml-3" v-if="comment.isEdit">
                                {{ trans('comments.editComment') }}
                            </small>

                            <span class="float-right">
                                <i class="icon fa fa-check" v-if="validateComment" v-on:click="onSubmit()"/>
                                <i class="icon fa fa-remove" v-if="comment.isEdit" v-on:click="onCancel()"/>
                            </span>
                        </div>

                        <b-form-textarea
                            id="commentTextArea"
                            v-model="comment.text"
                            :state="validateComment"
                            :placeholder="trans('comments.myComment')"
                            rows="5"
                            no-resize
                        />

                    </div>
                </div>
            </div>
        </div>

        <div class="text-center w-100 m-5" v-else>
            <b-spinner variant="secondary" label="Loading..."/>
        </div>

        <div class="w-100 border-card"></div>

        <div class="card bg-dark-blue-2 border-none w-100">
            <div class="card-body">
                <div class="row">
                    <div class="col-sm"></div>
                    <div class="col col-sm">
                        <b-pagination class="paginator text-center"
                                      v-model="paginator.currentPage"
                                      :total-rows="comments.length"
                                      :per-page="paginator.perPage"
                                      v-if="comments.length > paginator.perPage"
                                      aria-controls="commentsPaginator"
                                      align="center"
                                      limit="3"
                                      pills
                                      first-text="<<"
                                      last-text=">>"
                                      prev-text="<"
                                      next-text=">"
                        />
                    </div>
                    <div class="col-sm"></div>
                </div>
            </div>
        </div>

        <modal name="removeCommentConfirmModal" draggable adaptive height="auto" class="always-top-1">
            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('removeCommentConfirmModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('comments.deleteComment') }}
                    </div>

                    <p>
                        {{ trans('comments.deleteConfirmComment') }}
                    </p>

                    <div class="wrapper text-center">
                        <b-button-group size="lg">
                            <b-button variant="danger" v-on:click="onRemoveConfirm()">
                                {{ trans('comments.yes') }}
                            </b-button>
                            <b-button v-on:click="$modal.hide('removeCommentConfirmModal')">
                                {{ trans('comments.no') }}
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "comments",
        props: ['section', 'subsection'],
        data() {
            return {
                isLoaded: false,
                comment: {
                    id: 0,
                    text: '',
                    isEdit: false,
                    isRemove: false,
                    maxLength: 700,
                },
                comments: [],
                paginator: {
                    currentPage: 1,
                    perPage: 5,
                }
            }
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad: function() {
                let self = this;
                let section = this.$props['section'];
                let subsection = 0;

                self.comment = {
                    id: 0,
                    text: '',
                    isEdit: false,
                    maxLength: 700,
                };

                if (!section || typeof section === 'undefined')
                    return;

                if (typeof this.$props['subsection'] !== 'undefined')
                    subsection = parseInt(this.$props['subsection']);

                let url = '/app/comments/?section=' + section;

                if (subsection > 0) {
                    url += '&subsection=' + subsection;
                }

                axios.get(url).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.isLoaded = true;
                        self.comments = data.comments;
                        self.comment.maxLength = data.maxLength;
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: data.title,
                            text: data.message,
                            duration: 3000,
                        });
                    }
                });
            },
            onSubmit: function() {
                let self = this;
                let comment = this.comment;

                if (comment.text.length === 0) {
                    return;
                }

                let data = {
                    'text' : comment.text,
                    'section' : this.$props['section'],
                };

                if (typeof this.$props['subsection'] !== 'undefined')
                    data.subsection = parseInt(this.$props['subsection']);

                if (comment.isEdit) {
                    data.id = comment.id;
                }

                axios.post('/app/comments/add', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.onLoad();
                    }
                });
            },
            onEdit: function(comment) {
                this.comment.text = comment.text;
                this.comment.id = comment.id;
                this.comment.isEdit = true;

                let textarea = this.$el.querySelector("#commentTextArea");
                textarea.scrollIntoView();
            },
            onRemove: function(comment) {
                this.$modal.show('removeCommentConfirmModal');
                this.comment.id = comment.id;
            },
            onRemoveConfirm: function() {
                let self = this;
                let data = {
                    'id': this.comment.id,
                };

                if (!data)
                    return;

                axios.post('/app/comments/delete', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.$modal.hide('removeCommentConfirmModal');
                        self.onLoad();
                    }
                });
            },
            onCancel: function() {
                this.comment.text = '';
                this.comment.id = 0;
                this.comment.isEdit = false;
            }
        },
        computed: {
            ...mapGetters({
                 userData: 'getUserData'
            }),
            validateComment: function() {
                let length = this.comment.text.length;
                let maxLength = this.comment.maxLength;

                if (length === 0)
                    return null;

                return (length > 0 && length < maxLength);
            }
        }
    }
</script>
