<template>
    <div>

        <modal name="galleryModal" draggable adaptive height="auto" :width="768" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('galleryModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-icon mr-2" v-on:click="onUploadFormReset()">
                        <i class="fa fa-trash"/>
                    </div>

                    <div class="modal-title">
                        Dodaj obrazy
                    </div>

                    <b-form v-on:submit="onSubmitUploadImages" id="uploadForm">

                        <b-table dark striped hover sort-icon-left
                                 :items="uploader.files"
                                 :fields="uploader.table.fields"
                                 :per-page="uploader.table.perPage"
                                 :current-page="uploader.table.currentPage"
                                 id="uploaderTable"
                                 v-if="uploader.files.length"
                        >

                            <template slot="index" slot-scope="data">
                                {{ (uploader.table.currentPage - 1) * uploader.table.perPage + data.index + 1 }}
                            </template>

                            <template slot="thumb" slot-scope="data">
                                <img v-if="data.item.thumb" :src="data.item.thumb" width="90" height="auto" />
                                <span v-else>Brak</span>
                            </template>

                            <template slot="status" slot-scope="data">
                                <div v-if="data.item.error">
                                    Wystąpił błąd
                                </div>
                                <div v-else-if="data.item.success">
                                    Zapisany
                                </div>
                                <div v-else>
                                    Nowy
                                </div>
                            </template>

                            <template slot="size" slot-scope="data">
                                {{ convertFileSize(data.item.size) }}
                            </template>

                            <template slot="progressbar" slot-scope="data">
                                <div class="progress" v-if="data.item.active || data.item.progress !== '0.00'">
                                    <div :class="{'progress-bar': true, 'progress-bar-striped': true, 'bg-danger': data.item.error, 'progress-bar-animated': data.item.active}" role="progressbar" :style="{width: data.item.progress + '%'}">{{data.item.progress}}%</div>
                                </div>
                            </template>

                        </b-table>

                        <b-pagination class="paginator"
                                      v-model="uploader.table.currentPage"
                                      :total-rows="uploader.files.length"
                                      :per-page="uploader.table.perPage"
                                      aria-controls="uploaderTable"
                                      align="center"
                                      v-if="uploader.files.length > uploader.table.perPage"
                        />

                        <b-button-group class="mt-3">
                            <file-upload
                                class="btn btn-primary btn-square"
                                :headers="uploader.headers"
                                :post-action="uploader.url"
                                :extensions="uploader.filetype"
                                :accept="uploader.mime"
                                :multiple="uploader.multiple"
                                :size="uploader.size"
                                v-model="uploader.files"
                                @input-filter="onUploadFilter"
                                @input-file="onUploadAddFile"
                                :disabled="uploader.upload.pending || uploader.upload.success"
                                ref="upload">
                                <i class="fa fa-plus"/> Wybierz pliki lokalne
                            </file-upload>

                            <b-button variant="success" v-if="uploader.files.length && !uploader.upload.success && !uploader.upload.pending" type="submit">
                                <i class="fa fa-arrow-up" aria-hidden="true"/> Dodaj
                            </b-button>

                            <b-button variant="danger" v-on:click="onUploadStop()" v-if="uploader.files.length && !uploader.upload.success && uploader.upload.pending">
                                <i class="fa fa-stop" aria-hidden="true"/> Zatrzymaj
                            </b-button>

                            <b-button variant="info" v-on:click="onUploadFormReset()" v-if="uploader.files.length && uploader.upload.success">
                                <i class="fa fa-refresh" aria-hidden="true"/> Odśwież
                            </b-button>
                        </b-button-group>
                    </b-form>

                    <p class="mt-2">
                        Dozwolona wielkość dla pojedyńczego obrazu: <strong>{{ convertFileSize(uploader.size) }}</strong>
                    </p>

                </div>
            </div>
        </modal>

        <modal name="galleryRemoteModal" adaptive scrollable height="auto" width="100%" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('galleryRemoteModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        Dodaj obrazy zdalne
                    </div>

                    <div class="row text-center text-lg-left mt-2">
                        <div v-for="(element, i) in remoteImages"
                             :key="element.id" class="col-xs-6 col-sm-4 col-md-2 col-lg-2 mb-2">
                            <b-img thumbnail fluid
                                   :src="element.url"
                                   :alt="element.alt"
                                   v-bind:class="element.selected ? 'img-selected' : ''"
                                   v-on:click="onRemoteImageClick(i, element)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <div class="row">
            <!-- Nav Top Buttons -->
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin" v-on:click="onAddImageClick()">
                        Dodaj obrazy
                    </b-button>
                    <b-button type="button" class="btn-admin" v-on:click="onAddRemoteImageClick()">
                        Dodaj obrazy z serwera
                    </b-button>
                    <b-button type="button" class="btn-admin" v-on:click="onReloadClick()">
                        <i class="fa fa-refresh" aria-hidden="true"/>
                    </b-button>
                    <b-button type="button" class="btn-admin" v-on:click="onDeleteImageClick()" :disabled="noImageSelected">
                        Usuń zaznaczone obrazy
                    </b-button>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <b-pagination class="paginator text-center"
                              v-model="paginator.currentPage"
                              :total-rows="galleryImages.length"
                              :per-page="paginator.perPage"
                              aria-controls="galleryPaginator"
                              align="center"
                />
            </div>
        </div>

        <div class="row text-center text-lg-left mt-2">
            <div v-for="element in galleryImages.slice(this.paginator.perPage * (this.paginator.currentPage - 1), paginator.perPage * paginator.currentPage)"
                 :key="element.id" class="col-xs-6 col-sm-4 col-md-2 col-lg-2 mb-2">
                <b-img thumbnail fluid
                       :src="element.url"
                       :alt="element.alt"
                       v-bind:class="element.selected ? 'img-selected' : ''"
                       v-on:click="onImageClick(element)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                noImageSelected: true,
                paginator: {
                    currentPage: 1,
                    perPage: 18,
                },
                uploader: {
                    headers: {
                        'X-Csrf-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    url: '/admin/gallery/upload/',
                    filetype: 'gif,jpg,jpeg,png,webp',
                    mime: 'image/png,image/gif,image/jpeg,image/webp',
                    multiple: true,
                    size: 5242880, // 5MB
                    files: [],
                    upload: {
                        success: false,
                        pending: false,
                    },
                    table: {
                        perPage: 6,
                        currentPage: 1,
                        fields: [
                            {
                                key: 'index',
                                label: '#',
                            },
                            {
                                key: 'name',
                                label: 'Nazwa',
                            },
                            {
                                key: 'thumb',
                                label: 'Miniaturka',
                            },
                            {
                                key: 'status',
                                label: 'Status',
                            },
                            {
                                key: 'size',
                                label: 'Wielkość',
                            },
                            {
                                key: 'progressbar',
                                label: 'Postęp',
                            }
                        ]
                    }
                },
                galleryImages: [],
                remoteImages: [],
            }
        },
        mounted() {
            this.reloadGallery();
            this.getRemoteImages();
        },
        methods: {
            getRemoteImages: function() {
                let self = this;

                axios.get('/admin/gallery/remote/get/').then(function(response) {
                    let data = response.data;

                    if (data) {
                        self.remoteImages = data;
                    }
                });
            },
            reloadGallery: function() {
                let self = this;

                axios.get('/app/gallery/?full=1').then(function(response) {
                    let data = response.data;

                    if (data) {
                        self.galleryImages = data;
                    }
                });
            },
            onImageClick: function(e) {
                e.selected = !e.selected;

                for (let i in this.galleryImages) {
                    if (this.galleryImages[i].selected) {
                        this.noImageSelected = false;
                        return;
                    }
                }

                this.noImageSelected = true;
            },
            onRemoteImageClick: function(index, image) {
                let self = this;

                axios.post('/admin/gallery/add/', image).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.reloadGallery();
                        self.$delete(self.remoteImages, index);
                    }
                });
            },
            onAddImageClick: function() {
                this.$modal.show('galleryModal');
            },
            onAddRemoteImageClick: function() {
                this.$modal.show('galleryRemoteModal');
            },
            onReloadClick: function() {
                this.reloadGallery();
            },
            onDeleteImageClick: function() {
                if (this.noImageSelected) {
                    return;
                }

                let data = [];

                for (let i in this.galleryImages) {
                    if (this.galleryImages[i].selected) {
                        data.push(this.galleryImages[i].id);
                    }
                }

                if (data.length === 0) {
                    return;
                }

                let self = this;

                axios.post('/admin/gallery/delete', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.reloadGallery();
                        self.noImageSelected = true;
                    }
                });
            },
            onSubmitUploadImages: function(e) {
                e.preventDefault();

                // start upload
                this.$refs.upload.active = true;
                this.uploader.upload.pending = true;
            },
            onUploadStop: function(e) {
                e.preventDefault();

                // stop upload
                this.$refs.upload.active = false;
                this.uploader.upload.pending = false;
             },
            onUploadFormReset: function() {

                if (this.uploader.upload.pending) {
                    return;
                }

                this.uploader.files = [];
                this.uploader.upload.success = false;

                this.$refs.upload.clear();
            },
            onUploadFilter: function(newFile, oldFile, prevent) {
                // before upload
                if (newFile && !oldFile) {

                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                        return prevent()
                    }

                    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                        // Blob
                        newFile.blob = ''
                        let URL = window.URL || window.webkitURL
                        if (URL && URL.createObjectURL) {
                            newFile.blob = URL.createObjectURL(newFile.file)
                        }

                        // Thumbnails
                        newFile.thumb = ''
                        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
                            newFile.thumb = newFile.blob
                        }
                    }
                }
            },
            onUploadAddFile: function(newFile, oldFile) {
                if (typeof(newFile) === 'undefined') {
                    return;
                }
                // added
                if (newFile && !oldFile) {
                    let file = newFile;
                    let files = this.uploader.files;
                    let allowedSize = this.uploader.size;

                    if (file.size > allowedSize) {
                        this.$refs.upload.remove(file);
                        return;
                    }

                    if (files.length < 2)
                        return;

                    let amount = 0;

                    for (let i in files) {
                        if (files[i].name === file.name) {
                            amount++;

                            if (amount >= 2) {
                                this.$refs.upload.remove(file);
                                break;
                            }
                        }
                    }
                }
                // removed
                if (!newFile && oldFile) {

                }
                // Uploaded successfully
                if (newFile.success) {
                    this.uploader.upload.success = true;
                    this.uploader.upload.pending = false;

                    let response = JSON.parse(newFile.xhr.response);

                    if (!response.success) {
                        newFile.error = true;
                    }
                }
            }
        }
    }
</script>
