<template>
    <div>
        <command-button
            :command="openInsertVideoControl"
            :tooltip="trans('editor.tooltip.iframe')"
            icon="video"
        />

        <modal name="iframeUrlModal" adaptive height="auto" class="always-top-1">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('iframeUrlModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('editor.control.iframe.title') }}
                    </div>

                    <p class="small">
                        {{ trans('editor.control.iframe.example') }}: https://www.youtube.com/embed/N2nT-RtADi8
                    </p>

                    <b-form-group>
                        <b-form-input
                            id="urlInput"
                            type="text"
                            :placeholder="trans('editor.control.iframe.placeholder')"
                            v-model="url"
                            :state="urlValid"
                            trim
                        />
                    </b-form-group>

                    <b-form-group
                        id="formButtonGroup"
                    >
                        <b-button-group class="float-right">
                            <b-button type="button" v-on:click="onAddVideo()" v-bind:class="urlInvalid ? 'btn-secondary' : 'btn-success'" :disabled="urlInvalid">
                                {{ trans('editor.control.iframe.confirm') }}
                            </b-button>

                            <b-button type="button" v-on:click="$modal.hide('iframeUrlModal')">
                                {{ trans('editor.control.iframe.cancel') }}
                            </b-button>
                        </b-button-group>
                    </b-form-group>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import commandButton from './commandButton.vue';

    export default {
        name: 'buttonEmbedUrl',
        components: {
            commandButton,
        },
        props: {
            editorContext: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                url: '',
                urlInvalid: true,
            }
        },
        computed: {
            urlValid() {
                let url = this.url;

                if (url.length === 0) {
                    return null;
                }

                let pattern = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/);
                let result = pattern.test(url) && /embed/.test(url);

                this.urlInvalid = !result;

                return result;
            }
        },
        methods: {
            openInsertVideoControl () {
                this.$modal.show('iframeUrlModal');
            },
            onAddVideo() {
                let href = this.url;
                this.editorContext.commands.iframe({ src: href });
                this.$modal.hide('iframeUrlModal');
            },
        },
    };
</script>
