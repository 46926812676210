export default {
    "en": {
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "unauthorized": "Unauthorized access.",
            "notLogged": "Not logged.",
            "loginMinLength": "Login must have at least {l} characters.",
            "loginMaxLength": "Login is too long.",
            "loginInvalid": "Login is invalid.",
            "loginTooShort": "Login is too short.",
            "loginTooLong": "Login is too long.",
            "passwordTooShort": "Password is too short.",
            "passwordTooLong": "Password is too long.",
            "emailInvalid": "Invalid Email.",
            "notify": {
                "registerSuccess": {
                    "title": "Account has been created",
                    "message": "An message with the activation key has been sent to your email address."
                },
                "registerFail": {
                    "title": "Registration failed",
                    "error": "Error occured.",
                    "database": "An error occurred while processing the data.",
                    "token": "Provided token is invalid or has expired."
                },
                "resetLinkSuccess": {
                    "title": "A reminder has been sent",
                    "success": "A message has been sent to the email address provided to recover your password."
                },
                "resetLinkFail": {
                    "title": "Error occured",
                    "email": "Email not exists."
                },
                "changePasswordFail": {
                    "title": "Error occured",
                    "message": "Unauthorized attempt."
                },
                "resetPasswordSuccess": {
                    "title": "Password change completed.",
                    "message": "You can login using a new password."
                },
                "resetPasswordFail": {
                    "title": "Password change failed",
                    "message": "Password change link has been expired or is invalid."
                },
                "loginActivated": {
                    "title": "Account has been activated",
                    "message": "Login is now available."
                },
                "loginTokenExpired": {
                    "title": "The account activation link has expired.",
                    "message": "Contact the administrator."
                },
                "loginInvalidLink": {
                    "title": "Invalid wrong",
                    "message": "Link is incorrect."
                },
                "loginNotActive": {
                    "title": "Account is inactive",
                    "message": "This account is not active."
                },
                "loginBlock": {
                    "title": "Login Lock"
                },
                "loginInvalidParams": {
                    "title": "Login failed",
                    "message": "The login details provided are incorrect."
                },
                "loginSuccess": {
                    "title": "Login successful",
                    "message": "Last login: {time}"
                },
                "logout": {
                    "title": "Logged out"
                },
                "logoutFail": {
                    "title": "Error occured"
                },
                "userBanned": {
                    "title": "Login failed",
                    "text": "Your account is banned."
                }
            }
        },
        "comments": {
            "comments": "Comments",
            "noComments": "No comments.",
            "editComment": "Edit comment",
            "deleteComment": "Delete comment",
            "deleteConfirmComment": "Are you sure you want to delete this comment?",
            "myComment": "My comment...",
            "yes": "Yes",
            "no": "No",
            "notify": {
                "error": {
                    "title": "Error occured",
                    "message": "Comments could not be loaded."
                }
            },
            "sections": {
                "about": "About",
                "videos": "Video",
                "gallery": "Gallery",
                "download": "Download",
                "news": "News"
            }
        },
        "contact": {
            "notify": {
                "messageSend": {
                    "title": "Message has been sent",
                    "message": "Thank you for contact."
                },
                "messageFail": {
                    "title": "Error",
                    "message": "Failed to send message."
                }
            }
        },
        "download": {
            "files": "Files",
            "category": "Category",
            "size": "Size",
            "verReq": "Required game version",
            "notify": {
                "error": {
                    "title": "Error",
                    "message": "Failed to load file list."
                }
            },
            "help": "How to install"
        },
        "editor": {
            "tooltip": {
                "image": "Insert Image",
                "link": "Insert Link",
                "iframe": "Insert Video"
            },
            "control": {
                "insert_by_url": {
                    "title": "Insert image",
                    "confirm": "Insert",
                    "cancel": "Cancel",
                    "placeholder": "URL of image",
                    "invalid_url": "Please enter the correct url"
                },
                "insert_link": {
                    "title": "Insert link",
                    "confirm": "Insert",
                    "cancel": "Cancel",
                    "placeholder": "URL",
                    "invalid_url": "Please enter the correct url",
                    "noTextSelected": "Text must be selected."
                },
                "iframe": {
                    "title": "Insert video",
                    "confirm": "Insert",
                    "cancel": "Cancel",
                    "placeholder": "URL of video",
                    "invalid_url": "Please enter the correct url",
                    "example": "Example embed url"
                }
            }
        },
        "issues": {
            "title": "Issues",
            "description": "Here you can report an error concerning any modification element.",
            "select": "Choose section",
            "back": "Back",
            "us": "AM Campain",
            "ru": "RU Campain",
            "ar": "AR Campain",
            "sk": "Skirmish",
            "latest": "Recently updated issues",
            "author": "Author",
            "sort": "Sort",
            "noIssues": "No issues",
            "addIssue": "Add issue",
            "history": "My issue history",
            "noHistory": "No issue history.",
            "log": {
                "new": "Create a ticket",
                "edit": "Edit a ticket",
                "delete": "Delete a ticket"
            },
            "show": {
                "title": "Show:",
                "all": "All",
                "new": "New",
                "todo": "Todo",
                "inProgress": "In progress",
                "fixed": "Fixed",
                "notABug": "This is not a bug",
                "cantReproduce": "Abandoned",
                "myIssues": "My issues"
            },
            "notify": {
                "needLogin": {
                    "title": "You are not logged",
                    "text": "These functions are only available to registered users."
                },
                "taskInsertSuccess": {
                    "title": "Report added",
                    "message": "A new notification will be visible."
                },
                "taskEditSuccess": {
                    "title": "Request changed",
                    "message": "The content of the notification has been changed."
                },
                "taskDeleteSuccess": {
                    "title": "Report deleted",
                    "message": "Your issue is no longer visible."
                },
                "taskFail": {
                    "title": "An error has occurred",
                    "message": "Please try again or contact your administrator."
                }
            },
            "modal": {
                "issueFormModal": {
                    "title": "Add new issue",
                    "form": {
                        "name": {
                            "label": "label",
                            "placeholder": "Enter a concise title, e.g. Am09 - error from XYZ"
                        },
                        "collection": {
                            "label": "Type"
                        },
                        "clear": "Clear",
                        "view": "View",
                        "submit": "Submit"
                    }
                },
                "issueDeleteModal": {
                    "title": "Deleting an application",
                    "description": "Are you sure you want to delete this report?",
                    "yes": "Yes",
                    "no": "No"
                }
            }
        },
        "layout": {
            "about": "About",
            "download": "Download",
            "gallery": "Gallery",
            "video": "Videos",
            "faq": "FAQ",
            "menu": "Menu",
            "report": "Report an issue",
            "contact": "Contact",
            "register": "Register",
            "login": "Login",
            "logout": "Logout",
            "news": "News",
            "profile": "Profile",
            "account": "My account",
            "dialog-editor": "Dialog editor",
            "actualVer": "Latest ver.",
            "details": "Details",
            "content": "Actual Content",
            "phrase": {
                "lastUpdate": "Last update:",
                "selectCategory": "Select category",
                "noNews": "No news",
                "allNews": "All news"
            },
            "footer": {
                "contact": "Contact",
                "download": "Alternative sources",
                "description": "A group of dedicated fans devoting their free time to modify this great game which is Original War;)",
                "rights": "All Rights Reserved.",
                "terms": "Terms of use",
                "support": "Support"
            },
            "modal": {
                "register": "Register",
                "login": "Login",
                "name": "Name",
                "email": "E-mail",
                "password": "Password",
                "terms": "I accept the terms of service",
                "newsletter": "I want to receive notifications about news",
                "createAccount": "Create account",
                "rememberMe": "Remember Me",
                "forgotPassword": "Forgot password",
                "noAccount": "I do not have an account",
                "changePassword": "Change password",
                "newPassword": "New password",
                "change": "Change",
                "yes": "Yes",
                "no": "No",
                "contact": "Contact",
                "yourEmail": "Your e-mail address",
                "message": "Message",
                "send": "Send",
                "check": "Check",
                "paypal": {
                    "title": "Support",
                    "description": "The Sand of Siberia project has been created for many years thanks to the Original War passion. However, if you want, you can support the project by making a donation to its development."
                }
            }
        },
        "mail": {
            "confirm": {
                "title": "Account activation on the website",
                "text_1": "A service account has been created to this email address",
                "text_2": "Press the button below to complete the account registration process",
                "ignore": "Ignore this message if you have not set up an account on our website",
                "btn": "Activate"
            },
            "changePassword": {
                "title": "Password change",
                "text": "Click on the button below to confirm the password change",
                "btn": "Change the password"
            }
        },
        "news": {
            "filter": {
                "all": "All"
            },
            "empty": "No news found.",
            "noNewsFound": "No such entry found.",
            "list": "List",
            "created_at": "Published"
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "passwords": {
            "password": "Passwords must be at least six characters.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "profile": {
            "info": "General information",
            "comments": "Comments",
            "edit": "Change the data",
            "userSince": "Account Registered",
            "lastActive": "Last Active",
            "owPlayerSince": "Original War player since",
            "multiplayerNick": "Nickname in a multiplayer game",
            "favStreamer": "Favorite streamer",
            "localization": "Location",
            "website": "Site",
            "about": "About Yourself",
            "email": "Email address",
            "password": "New password",
            "save": "Save",
            "changePassword": "Change the password",
            "passwordConfirm": "Confirm password",
            "noComments": "No comments yet.",
            "notify": {
                "email": {
                    "title": "Editing email address",
                    "text": "Changing the email address requires contacting the administration."
                },
                "data": {
                    "title": "Data saved successfully",
                    "text": "The information provided is already visible."
                },
                "fail": {
                    "title": "An error has occurred",
                    "text": "Please try again."
                },
                "avatar": {
                    "title": "An error has occurred",
                    "text": "Not Found Image"
                }
            },
            "modal": {
                "avatar": {
                    "title": "Avatar",
                    "text": "Select an image from the list or enter the URL",
                    "placeholder": "Enter the image address"
                }
            }
        },
        "terms": {
            "cookie": {
                "title": "Cookie",
                "message": "This website uses cookies for statistical and functional purposes. Thanks to this, it is individually adapted to your needs. By staying on the site you automatically agree."
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "videos": {
            "sosyt": "Sand of Siberia on YouTube",
            "author": "Author",
            "views": "Views",
            "notify": {
                "error": {
                    "title": "Error occured",
                    "message": "Failed to load video content."
                }
            }
        }
    },
    "pl": {
        "auth": {
            "failed": "Nie znaleziono wyników.",
            "throttle": "Przekroczono limit prób logowania. Prosimy spróbować ponownie za {seconds} sekund.",
            "unauthorized": "Nieautoryzowany dostęp.",
            "notLogged": "Użytkownik nie jest zalogowany.",
            "loginMinLength": "Login musi mieć przynajmniej {l} znaków.",
            "loginMaxLength": "Podany login jest za długi.",
            "loginInvalid": "Podany login jest błędny.",
            "loginTooShort": "Nazwa użytkownika jest za krótka.",
            "loginTooLong": "Nazwa użytkownika jest za długa.",
            "passwordTooShort": "Podane hasło jest za krótkie.",
            "passwordTooLong": "Podane hasło jest za długie.",
            "emailInvalid": "Nieprawidłowy adres email.",
            "notify": {
                "registerSuccess": {
                    "title": "Konto zostało utworzone",
                    "message": "Na podany adres e-mail została wysłana wiadomość z kluczem aktywacyjnym."
                },
                "registerFail": {
                    "title": "Rejestracja nieudana",
                    "error": "Wystąpił błąd.",
                    "database": "Wystąpił błąd podczas przetwarzania danych.",
                    "token": "Podany token jest nieprawidłowy lub wygasł."
                },
                "resetLinkSuccess": {
                    "title": "Przypomnienie zostało wysłane",
                    "success": "Na podany adres email została wysłana wiadomość umożliwiająca odzyskanie hasła."
                },
                "resetLinkFail": {
                    "title": "Wystąpił błąd",
                    "email": "Podany adres email nie istnieje."
                },
                "resetPasswordSuccess": {
                    "title": "Zmiana hasła zakończona pomyślnie",
                    "message": "Można zalogować się za pomocą nowego hasła."
                },
                "resetPasswordFail": {
                    "title": "Zmiana hasła nieudana",
                    "message": "Link zmiany hasła wygasł lub jest nieprawidłowy."
                },
                "changePasswordFail": {
                    "title": "Zmiana hasła nieudana",
                    "message": "Błąd autoryzacji."
                },
                "loginActivated": {
                    "title": "Konto zostało aktywowane",
                    "message": "Logowanie jest już dostępne."
                },
                "loginTokenExpired": {
                    "title": "Link aktywujący konto wygasł",
                    "message": "Skontaktuj się z administratorem."
                },
                "loginInvalidLink": {
                    "title": "Nieprawidłowy link",
                    "message": "Ten link jest niepoprawny."
                },
                "loginNotActive": {
                    "title": "Konto nieaktywne",
                    "message": "To konto nie jest aktywne."
                },
                "loginBlock": {
                    "title": "Blokada logowania"
                },
                "loginInvalidParams": {
                    "title": "Logowanie nieudane",
                    "message": "Podane dane logowania są błędne."
                },
                "loginSuccess": {
                    "title": "Logowanie zakończone pomyślnie",
                    "message": "Ostatnie logowanie: {time}"
                },
                "logout": {
                    "title": "Wylogowano."
                },
                "logoutFail": {
                    "title": "Wystąpił błąd."
                },
                "userBanned": {
                    "title": "Logowanie nieudane",
                    "text": "Twoje konto zostało zablokowane."
                }
            }
        },
        "comments": {
            "comments": "Komentarze",
            "noComments": "Brak komentarzy.",
            "editComment": "edytowanie komentarza",
            "deleteComment": "Usuwanie komentarza",
            "deleteConfirmComment": "Czy na pewno chcesz usunąć ten komentarz?",
            "myComment": "Mój komentarz...",
            "yes": "Tak",
            "no": "Nie",
            "notify": {
                "error": {
                    "title": "Wystąpił błąd",
                    "message": "Nie udało się wczytać komentarzy."
                }
            },
            "sections": {
                "about": "O projekcie",
                "videos": "Wideo",
                "gallery": "Galeria",
                "download": "Pobierz",
                "news": "Aktualności"
            }
        },
        "contact": {
            "notify": {
                "messageSend": {
                    "title": "Wiadomość została wysłana",
                    "message": "Dziękujemy za kontakt."
                },
                "messageFail": {
                    "title": "Wystąpił błąd",
                    "message": "Nie udało się wysłać wiadomości."
                }
            }
        },
        "download": {
            "files": "Pliki",
            "category": "Kategoria",
            "size": "Wielkość",
            "verReq": "Wymagana wersja gry",
            "notify": {
                "error": {
                    "title": "Wystąpił błąd",
                    "message": "Nie udało się wczytać listy plików."
                }
            },
            "help": "Instrukcja instalacji"
        },
        "editor": {
            "tooltip": {
                "image": "Dodaj obraz",
                "link": "Dodaj URL",
                "iframe": "Dodaj film"
            },
            "control": {
                "insert_by_url": {
                    "title": "Dodaj obraz",
                    "confirm": "Dodaj",
                    "cancel": "Zamknij",
                    "placeholder": "URL obrazu",
                    "invalid_url": "Proszę podać prawidłowy link prowadzący do obrazu"
                },
                "insert_link": {
                    "title": "Dodaj URL",
                    "confirm": "Dodaj",
                    "cancel": "Zamknij",
                    "placeholder": "URL",
                    "invalid_url": "Proszę podać prawidłowy link",
                    "noTextSelected": "Tekst musi być zaznaczony."
                },
                "iframe": {
                    "title": "Dodaj film",
                    "confirm": "Dodaj",
                    "cancel": "Zamknij",
                    "placeholder": "URL",
                    "invalid_url": "Proszę podać prawidłowy link prowadzący do filmu",
                    "example": "Przykładowy URL filmu"
                }
            }
        },
        "issues": {
            "title": "Zgłoszenia",
            "description": "W tym miejscu możesz zgłosić błąd dotyczący dowolnego elementu modyfikacji.",
            "select": "Wybierz sekcję",
            "back": "Wróć",
            "us": "Kampania amerykańska",
            "ru": "Kampania rosyjska",
            "ar": "Kampania arabska",
            "sk": "Potyczki i inne",
            "latest": "Ostatnio zaktualizowane zgłoszenia",
            "author": "Autor",
            "sort": "Sortuj",
            "noIssues": "Brak zgłoszeń",
            "addIssue": "Dodaj zgłoszenie",
            "history": "Moja historia zgłoszeń",
            "noHistory": "Brak historii zgłoszeń.",
            "log": {
                "new": "Utworzenie zgłoszenia",
                "edit": "Edycja zgłoszenia",
                "delete": "Usunięcie zgłoszenia"
            },
            "show": {
                "title": "Pokaż:",
                "all": "Wszystkie",
                "new": "Nowe",
                "todo": "Do zrobienia",
                "inProgress": "W trakcie",
                "fixed": "Zrealizowane",
                "notABug": "To nie jest błąd",
                "cantReproduce": "Porzucone",
                "myIssues": "Moje zgłoszenia"
            },
            "notify": {
                "needLogin": {
                    "title": "Nie jesteś zalogowany",
                    "text": "Te funkcje dostępne są wyłącznie dla zalogowanych."
                },
                "taskInsertSuccess": {
                    "title": "Zgłoszenie dodane",
                    "message": "Nowe zgłoszenie wkrótce będzie widoczne."
                },
                "taskEditSuccess": {
                    "title": "Zgłoszenie zmienione",
                    "message": "Zmieniono treść zgłoszenia."
                },
                "taskDeleteSuccess": {
                    "title": "Zgłoszenie usunięte",
                    "message": "Twoje zgłoszenie nie jest już widoczne."
                },
                "taskFail": {
                    "title": "Wystąpił błąd",
                    "message": "Spróbuj ponownie lub skontaktuj się z administratorem."
                }
            },
            "modal": {
                "issueFormModal": {
                    "title": "Dodaj nowe zgłoszenie",
                    "form": {
                        "name": {
                            "label": "Nazwa",
                            "placeholder": "Podaj zwięzwły tytuł np. Am09 - błąd z XYZ"
                        },
                        "collection": {
                            "label": "Typ"
                        },
                        "clear": "Wyczyść",
                        "view": "Pogląd",
                        "submit": "Wyślij"
                    }
                },
                "issueDeleteModal": {
                    "title": "Usuwanie zgłoszenia",
                    "description": "Czy na pewno chcesz usunąć to zgłoszenie?",
                    "yes": "Tak",
                    "no": "Nie"
                }
            }
        },
        "layout": {
            "about": "O projekcie",
            "download": "Pobierz",
            "gallery": "Galeria",
            "video": "Wideo",
            "faq": "Pomoc",
            "menu": "Menu",
            "report": "Zgłoś błąd",
            "contact": "Kontakt",
            "register": "Załóż konto",
            "login": "Zaloguj się",
            "logout": "Wyloguj się",
            "news": "Nowości",
            "profile": "Profil",
            "account": "Moje konto",
            "dialog-editor": "Edytor dialogów",
            "actualVer": "Najnowsza wersja",
            "details": "Szczegóły",
            "content": "Aktualna zawartość",
            "phrase": {
                "lastUpdate": "Ostatnia aktualizacja:",
                "selectCategory": "Wybierz kategorię",
                "noNews": "Brak aktualności",
                "allNews": "Wszystkie aktualności"
            },
            "footer": {
                "contact": "Kontakt",
                "download": "Alternatywne źródła",
                "description": "Grupa oddanych fanów poświęcających swój wolny czas na modyfikowanie tej świetnej gry jaką jest Original War ;)",
                "rights": "Wszelkie prawa zastrzeżone.",
                "terms": "Zasady korzystania z serwisu",
                "support": "Wsparcie"
            },
            "modal": {
                "register": "Rejestracja",
                "login": "Logowanie",
                "name": "Nazwa",
                "email": "E-mail",
                "password": "Hasło",
                "terms": "Akceptuje regulamin serwisu",
                "newsletter": "Chcę otrzymywać powiadomienia o nowościach",
                "createAccount": "Załóż konto",
                "rememberMe": "Zapamiętaj Mnie",
                "forgotPassword": "Przypominanie hasła",
                "noAccount": "Nie mam konta",
                "changePassword": "Zmień hasło",
                "newPassword": "Nowe hasło",
                "change": "Zmień",
                "yes": "Tak",
                "no": "Nie",
                "contact": "Formularz kontaktowy",
                "yourEmail": "Twój adres e-mail",
                "message": "Wiadomość",
                "send": "Wyślij",
                "check": "Zobacz",
                "paypal": {
                    "title": "Wsparcie",
                    "description": "Projekt Sand of Siberia powstaje od wielu lat dzięki pasji, jaką jest Original War. Jednak, jeśli chcesz możesz wesprzeć projekt przekazując darowiznę na jego rozwój."
                }
            }
        },
        "mail": {
            "confirm": {
                "title": "Aktywacja konta w serwisie",
                "text_1": "Na ten adres e-mail zostało założone konto w serwisie",
                "text_2": "Wciśnij poniższy przycisk aby dokończyć proces rejestracji konta",
                "ignore": "Zignoruj tą wiadomość jeżeli nie zakładałeś konta w naszym serwisie",
                "btn": "Aktywuj"
            },
            "changePassword": {
                "title": "Zmiana hasła",
                "text": "Kliknij na poniższy przycisk aby potwierdzić zmianę hasła",
                "btn": "Zmień hasło"
            }
        },
        "news": {
            "filter": {
                "all": "Wszystkie"
            },
            "empty": "Nie znaleziono żadnych nowości.",
            "noNewsFound": "Nie znaleziono takiego wpisu.",
            "list": "Lista",
            "created_at": "Opublikowano"
        },
        "pagination": {
            "previous": "&laquo; Wstecz",
            "next": "Dalej &raquo;"
        },
        "passwords": {
            "password": "Hasło musi składać się przynajmniej z 6 znaków.",
            "reset": "Twoje hasło zostało zmienione!",
            "sent": "Na podany adres e-mail został wysłany link umożliwiający zmianę hasła!",
            "token": "Token zmiany hasła jest nieprawidłowy.",
            "user": "Nie znaleziono użytkownika dla podanego adresu e-mail."
        },
        "profile": {
            "info": "Informacje ogólne",
            "comments": "Komentarze",
            "edit": "Zmień dane",
            "userSince": "Konto zarejestrowane",
            "lastActive": "Ostatnio aktywny",
            "owPlayerSince": "Gracz Original War od",
            "multiplayerNick": "Nickname w grze wieloosobowej",
            "favStreamer": "Ulubiony streamer",
            "localization": "Lokalizacja",
            "website": "Witryna",
            "about": "O sobie",
            "email": "Adres e-mail",
            "password": "Nowe hasło",
            "save": "Zapisz",
            "changePassword": "Zmień hasło",
            "passwordConfirm": "Potwierdź hasło",
            "noComments": "Brak komentarzy.",
            "notify": {
                "email": {
                    "title": "Edycja adresu e-mail",
                    "text": "Zmiana adresu e-mail wymaga kontaktu z administracją."
                },
                "data": {
                    "title": "Dane zapisane pomyślnie",
                    "text": "Podane informacje są już widoczne."
                },
                "fail": {
                    "title": "Wystąpił błąd",
                    "text": "Spróbuj ponownie."
                },
                "avatar": {
                    "title": "Wystąpił błąd",
                    "text": "Nie znaleziono obrazu"
                }
            },
            "modal": {
                "avatar": {
                    "title": "Awatar",
                    "text": "Wybierz wizerunek z listy lub podaj URL",
                    "placeholder": "Podaj adres obrazu"
                }
            }
        },
        "terms": {
            "cookie": {
                "title": "Ciasteczka",
                "message": "Ta strona internetowa używa plików cookies (tzw. ciasteczkek) w celach statystycznych oraz funkcjonalnych. Dzięki temu jest ona indywidualnie dostosowywana do twoich potrzeb. Pozostając na stronie automatycznie wyrażasz zgodę."
            }
        },
        "validation": {
            "accepted": "{attribute} musi być zaakceptowany.",
            "active_url": "{attribute} nie jest prawidłowym adresem URL.",
            "after": "{attribute} musi być datą po {date}.",
            "after_or_equal": "{attribute} musi być równy lub późniejszy {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "{attribute} musi być prawidłowym adresem e-mail.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "{attribute} musi być zaznaczony.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "uuid": "The {attribute} must be a valid UUID.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": []
        },
        "videos": {
            "sosyt": "Sand of Siberia na YouTube",
            "author": "Autor",
            "views": "Wyświetleń",
            "notify": {
                "error": {
                    "title": "Wystapił błąd",
                    "message": "Nie udało się wczytać materiałów wideo."
                }
            }
        }
    }
}
