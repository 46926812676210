<template>
    <div>
        <div class="row">

            <div class="col-lg-3 col-md-6">
                <div class="card text-white card-success">
                    <div class="card-body bg-success">
                        <div class="rotate">
                            <i class="fa fa-user fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Liczba użytkowników</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.users" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white card-danger">
                    <div class="card-body bg-danger">
                        <div class="rotate">
                            <i class="fa fa-list fa-5x"></i>
                        </div>

                        <h6 class="text-uppercase">Liczba komentarzy</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.comments" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white card-info">
                    <div class="card-body bg-info">
                        <div class="rotate">
                            <i class="fas fa-door-open fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Odwiedzin w ostatnim tygodniu</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.visits" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white card-warning">
                    <div class="card-body bg-warning">
                        <div class="rotate">
                            <i class="fas fa-download fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Łączna suma pobrań</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.downloads" />
                        </h1>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-4">
            <div class="col-lg-3 col-md-6">
                <div class="card text-white">
                    <div class="card-body bg-dark-blue-3">
                        <div class="rotate">
                            <i class="fas fa-bug fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Liczba otwartych zgłoszeń</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.issues" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white">
                    <div class="card-body bg-dark">
                        <div class="rotate">
                            <i class="fas fa-newspaper fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Liczba newsów</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.news" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white">
                    <div class="card-body bg-coral">
                        <div class="rotate">
                            <i class="fas fa-photo-video fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Liczba filmów</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.videos" />
                        </h1>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="card text-white">
                    <div class="card-body bg-purple">
                        <div class="rotate">
                            <i class="far fa-images fa-5x"></i>
                        </div>
                        <h6 class="text-uppercase">Liczba zdjęć</h6>
                        <h1 class="display-1">
                            <anim-number :number="stats.gallery" />
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AnimNumber from "../../components/animNumber";
    export default {
        components: {AnimNumber},
        data() {
            return {
                stats: {
                    'users' : 0,
                    'comments' : 0,
                    'visits' : 0,
                    'downloads' : 0,
                    'issues' : 0,
                    'news' : 0,
                    'videos' : 0,
                    'gallery' : 0,
                },
            }
        },
        mounted() {
            let self = this;

            axios.get('/admin/dashboard/get/').then(function(response) {
                self.stats = response.data;
            });
        }
    }
</script>
