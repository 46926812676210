<template>
    <div>
        <div class="row">
            <!-- Nav Top Buttons -->
            <!--<div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin">
                        Usuń komentarz
                    </b-button>
                </div>
            </div>-->

            <!-- Data Table -->
            <div class="col-lg-12 col-md-8">
                <div class="table-responsive">
                    <div class="text-center m-5" v-if="!loaded">
                        <b-spinner variant="secondary"/>
                    </div>

                    <b-table dark striped hover sort-icon-left
                             :items="comments"
                             :fields="commentFields"
                             :per-page="paginator.perPage"
                             :current-page="paginator.currentPage"
                             @row-clicked="onSelectComment"
                             tbody-tr-class="clickable"
                             id="commentsTable"
                             v-else-if="comments.length > 0">

                        <template v-slot:cell(section)="data">
                            {{ sections[data.item.id_section] }}
                        </template>

                        <template v-slot:cell(author)="data">
                            {{ data.item.user.name }}
                        </template>
                    </b-table>

                    <p v-else-if="comments.length === 0">
                        Brak rekordów.
                    </p>

                    <div class="mt-3">
                        <b-pagination class="paginator"
                                      v-model="paginator.currentPage"
                                      :total-rows="comments.length"
                                      :per-page="paginator.perPage"
                                      aria-controls="commentsTable"
                                      align="center"
                                      v-if="comments.length > paginator.perPage">
                        </b-pagination>
                    </div>
                </div>
            </div>
        </div>

        <modal name="deleteCommentModal" scrollable adaptive height="auto" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('deleteCommentModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        Usuwanie komentarza?
                    </div>

                    <p>
                        Czy na pewno chcesz usunąć ten komentarz?
                    </p>

                    <div class="row">
                        <div class="col"></div>

                        <div class="col">
                            <b-button-group class="mt-2">
                                <b-button type="button" variant="danger" size="lg" v-on:click="onDeleteComment">
                                    Tak
                                </b-button>

                                <b-button type="button" size="lg" v-on:click="$modal.hide('deleteCommentModal')">
                                    Nie
                                </b-button>
                            </b-button-group>
                        </div>

                        <div class="col"></div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loaded: false,
                sections: [
                    'About',
                    'Videos',
                    'Gallery',
                    'Download',
                    'News',
                ],
                selectedID: 0,
                comments: {},
                commentFields: [
                    {
                        key: 'id',
                        sortable: true,
                        label: '#',
                    },
                    {
                        key: 'section',
                        sortable: true,
                        label: 'Sekcja',
                    },
                    {
                        key: 'author',
                        sortable: true,
                        label: 'Autor',
                    },
                    {
                        key: 'text',
                        label: 'Tekst',
                    },
                    {
                        key: 'created_at',
                        sortable: true,
                        label: 'Utworzony',
                    },
                    {
                        key: 'updated_at',
                        sortable: true,
                        label: 'Ostatnio edytowany',
                    }
                ],
                paginator: {
                    perPage: 20,
                    currentPage: 1,
                }
            }
        },
        mounted() {
            this.reloadComments();
        },
        methods: {
            reloadComments: function() {
                let self = this;

                axios.get('/admin/comments/get/').then(function(response) {
                    self.comments = response.data.comments;
                    self.loaded = true;
                });
            },
            onSelectComment: function(comment) {
                this.selectedID = comment.id;
                this.$modal.show('deleteCommentModal');
            },
            onDeleteComment: function() {
                let data = {
                    'id' : this.selectedID,
                };

                let self = this;

                axios.post('/admin/comments/delete/', data).then(function(response) {
                    let data = response.data;

                    self.$modal.hide('deleteCommentModal');

                    if (data.success) {
                        self.loaded = false;
                        self.reloadComments();
                    }
                });
            }
        }
    }
</script>
