<template>
    <div class="container">
        <apexchart :options="options" :series="series"></apexchart>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tabIndex: 0,
                options: {
                    chart: {
                        id: 'vue-chart-1',
                        type: 'bar',
                        foreColor: '#ffffff',
                    },
                    xaxis: {
                        categories: [],
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    legend: {
                        show: true,
                        position: 'bottom',
                    },
                    theme: {
                        monochrome: {
                            enabled: true,
                            color: '#255aee',
                            shadeTo: 'light',
                            shadeIntensity: 0.65
                        },
                    },
                    tooltip: {
                        enabled: false,
                    },
                    title: {
                        text: 'Liczba odwiedzin w ostatnim roku',
                    }
                },
                series: [],
            }
        },
        mounted() {
            let self = this;

            axios.get('/admin/statistics/get/').then(function(response) {
                self.updateChart(response.data);
            });
        },
        methods: {
            updateChart: function(data) {
                let self = this;

                self.options = {...self.options, ...{
                    xaxis: {
                        categories: data.categories
                    }
                }};

                self.series = data.series;
            }
        }
    }
</script>
