<template>
    <div>
        <command-button
            :command="openUrlPrompt"
            :tooltip="trans('editor.tooltip.image')"
            icon="image"
        />

        <modal name="imageUrlModal" adaptive height="auto" class="always-top-1">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('imageUrlModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('editor.control.insert_by_url.title') }}
                    </div>

                    <b-form-group>
                        <b-form-input
                            id="urlInput"
                            type="text"
                            :placeholder="trans('editor.control.insert_by_url.placeholder')"
                            v-model="url"
                            :state="urlValid"
                            trim
                        />
                    </b-form-group>

                    <b-form-group
                        id="formButtonGroup"
                    >
                        <b-button-group class="float-right">
                            <b-button type="button" v-on:click="onAddImage()" v-bind:class="urlInvalid ? 'btn-secondary' : 'btn-success'" :disabled="urlInvalid">
                                {{ trans('editor.control.insert_by_url.confirm') }}
                            </b-button>

                            <b-button type="button" v-on:click="$modal.hide('imageUrlModal')">
                                {{ trans('editor.control.insert_by_url.cancel') }}
                            </b-button>
                        </b-button-group>
                    </b-form-group>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import { readFileDataUrl } from './utils/shared';
    import commandButton from './commandButton';

    export default {
        name: 'buttonImageUrl',
        components: {
            commandButton,
        },
        props: {
            editorContext: {
                type: Object,
                required: true,
            },
        },
        data () {
            return {
                imageUploadDialogVisible: false,
                url: '',
                urlInvalid: true,
            };
        },
        computed: {
            imageNodeOptions () {
                return this.editorContext.editor.extensions.options.image;
            },
            urlValid() {
                let url = this.url;

                if (url.length === 0) {
                    return null;
                }

                let pattern = new RegExp(this.imageNodeOptions.urlPattern);
                let result = pattern.test(url);

                this.urlInvalid = !result;

                return result;
            }
        },
        methods: {
            /*openUrlPrompt () {
                this.$prompt('', this.trans('editor.control.insert_by_url.title'), {
                    confirmButtonText: this.trans('editor.control.insert_by_url.confirm'),
                    cancelButtonText: this.trans('editor.control.insert_by_url.cancel'),
                    inputPlaceholder: this.trans('editor.control.insert_by_url.placeholder'),
                    inputPattern: this.imageNodeOptions.urlPattern,
                    inputErrorMessage: this.trans('editor.control.insert_by_url.invalid_url'),
                    roundButton: true,
                }).then(({ value: url }) => {
                    this.editorContext.commands.image({ src: url });
                }).catch(() => {
                });
            },*/
            openUrlPrompt() {
                this.$modal.show('imageUrlModal');
            },
            onAddImage() {
                this.editorContext.commands.image({ src: this.url });
                this.$modal.hide('imageUrlModal');
            },
            async uploadImage (uploadOptions) {
                const { file } = uploadOptions;
                const uploadRequest = this.imageNodeOptions.uploadRequest;
                const url = await (uploadRequest ? uploadRequest(file) : readFileDataUrl(file));
                this.editorContext.commands.image({ src: url });
                this.imageUploadDialogVisible = false;
            },
        },
    };
</script>
