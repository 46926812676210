window.Vue = require('vue');
zxcvbn = require('zxcvbn');


Vue.mixin({
    methods: {
        validateEmail: function(email) {
            if (typeof email === 'undefined' || email === null)
                return false;

            const rgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return rgx.test(String(email).toLowerCase());
        },
        validateURL: function(url) {
            const rgx = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
            return rgx.test(String(url).toLowerCase());
        },
        convertFileSize: function(size) {
            if (size === 0) return '0 B';

            const k = 1024;
            const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(size) / Math.log(k));

            return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + units[i];
        },
        updatePasswordStrength(password) {
            let strength = zxcvbn(password);
            return strength.score;
        },
        trans(string) {
            return this.$t(string);
        },
        transP(string, params) {
            return this.$t(string, params);
        },
        capitalize(s) {
            if (typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    }
});

Vue.directive('plaintext', {
    bind(el, binding, vnode) {
        el.innerHTML = el.innerText;
    }
});
