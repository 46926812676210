<template>
    <footer class="section footer-classic context-dark bg-image bg-dark-blue-2 mt-5 p-3">
        <div class="container">
            <div class="row row-30">
                <div class="col-md-4 col-xl-5">
                    <div class="pr-xl-4">
                        <img class="brand-logo-light" src="images/logo-sosteam.png" alt="sosteam">
                        <p>
                            {{ trans('layout.footer.description') }}
                        </p>
                        <p class="rights">
                            <span>© </span>
                            <span class="copyright-year">
                                2012-{{ (new Date()).getFullYear() }}
                            </span>
                            <span>SoSteam - </span>
                            <span>{{ trans('layout.footer.rights') }}</span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <h5 class="text-white">
                        {{ trans('layout.footer.contact') }}
                    </h5>

                    <dl class="contact-list">
                        <dd>
                            <a href="mailto:contact@sand-of-siberia.pl">
                                contact@sand-of-siberia.pl
                            </a>
                        </dd>
                    </dl>

                    <ul class="footer-list">
                        <li class="pt-0">
                            <router-link :to="{ name: 'terms' }">
                                {{ trans('layout.footer.terms') }}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-md-4 col-xl-3">
                    <h5 class="text-white">
                        {{ trans('layout.footer.download') }}
                    </h5>
                    <ul class="footer-list">
                        <li class="pt-0">
                            <a href="https://original-war.net/files.php?id=186" target="_blank">
                                original-war.net
                            </a>
                        </li>

                        <li class="pt-0">
                            <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=582832390" target="_blank">
                                Steam Workshop
                            </a>
                        </li>

                        <li class="pt-0">
                            <a href="https://www.moddb.com/mods/sand-of-siberia/" target="_blank">
                                ModDB
                            </a>
                        </li>

                        <li class="pt-0">
                            <a href="https://www.gry-online.pl/S030.asp?ID=71287" target="_blank">
                                gry-online.pl
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
        }
    }
</script>
