<template>
    <div>
        <div class="swiper-container" ref="swiper" v-show="loaded">
            <div class="swiper-wrapper">
                <div class="swiper-slide clickable" v-for="slide in slides" :style="{ backgroundImage: `url('${slide.image_url}')` }" v-on:click="onClickSlider(slide.url)">
                    <div class="swiper-inside noselect">
                        {{ slide.text }}
                    </div>
                </div>
            </div>
            <!-- Pagination -->
            <div class="swiper-pagination"></div>
            <!-- Arrows -->
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
        </div>

        <div class="text-center m-5" v-if="!loaded">
            <b-spinner variant="secondary"/>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper/dist/js/swiper.esm.bundle';

    export default {
        data() {
            return {
                swiper: null,
                loaded: false,
                slides: {},
            }
        },
        beforeCreate() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            let self = this;

            axios.get('/app/swiper/get/').then(function(response) {
                let data = response.data;

                if (data.success) {
                    self.loaded = true;
                    self.slides = data.swiper;
                    self.$store.commit('removeFromAppQueue');

                    self.$nextTick(() => {
                        self.swiper = new Swiper(self.$refs.swiper, {
                            init: true,
                            loop: true,
                            grabCursor: true,
                            preloadImages: true,
                            allowTouchMove: false,
                            observer: true,
                            observeParents: true,
                            pagination: {
                                el: '.swiper-pagination',
                            },
                            navigation: {
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                            },
                            autoplay: {
                                delay: 5000,
                                disableOnInteraction: false,
                            }
                        });
                    });
                }
            });
        },
        methods: {
            onClickSlider(url) {
                if (url.indexOf('https') > -1) {
                    window.location.replace(url)
                } else {
                    this.$router.push(url);
                }
            }
        }
    }
</script>
