<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body" v-if="loaded">
            <h4 class="card-title">
                {{ trans('layout.profile') }} - {{ user.name }}
            </h4>

            <div class="container mt-3">
                <div class="row">
                    <div class="col-lg-10 col-12 col-sm-10">
                        <b-tabs nav-class="tabs-blue" active-nav-item-class="tabs-nav-blue">
                            <b-tab :title="trans('profile.info')" lazy>
                                <div class="bg-dark-blue-2 border-blue p-2">
                                    <span class="d-block" v-if="user.registerDate.length">
                                        {{ trans('profile.userSince') }}: {{ user.registerDate }}
                                    </span>
                                    <span class="d-block" v-if="user.lastActive.length">
                                        {{ trans('profile.lastActive') }}: {{ user.lastActive }}
                                    </span>
                                    <span class="d-block" v-if="user.owPlayerSince">
                                        {{ trans('profile.owPlayerSince') }}: {{ user.owPlayerSince }}
                                    </span>
                                    <span class="d-block" v-if="user.multiplayerNick.length">
                                        {{ trans('profile.multiplayerNick') }}: {{ user.multiplayerNick }}
                                    </span>
                                    <span class="d-block" v-if="user.favStreamer.length">
                                        {{ trans('profile.favStreamer') }}: {{ user.favStreamer }}
                                    </span>
                                    <span class="d-block" v-if="user.localization.length">
                                        {{ trans('profile.localization') }}: {{ user.localization }}
                                    </span>
                                    <span class="d-block" v-if="user.website.length">
                                        {{ trans('profile.website') }}: <a :href="user.website">{{ user.website }}</a>
                                    </span>
                                    <span class="d-block" v-if="user.about.length">
                                        {{ trans('profile.about') }}: {{ user.about }}
                                    </span>
                                </div>
                            </b-tab>

                            <b-tab :title="trans('profile.comments') + ' [' + (comments.length ? comments.length : '0') + ']'" lazy>
                                <p class="text-center mt-3" v-if="comments.length === 0">
                                    {{ trans('profile.noComments') }}
                                </p>

                                <b-list-group v-else>
                                    <b-list-group-item v-for="comment in comments.slice(paginator.perPage * (paginator.currentPage - 1), paginator.perPage * paginator.currentPage)"
                                                       :key="comment.id"
                                                       v-on:click="onCommentClick(comment)"
                                                       class="clickable"
                                    >
                                        <div class="w-100 d-inline-block">
                                            <p class="float-left text-left font-weight-bold small">
                                                {{ sections[comment.id_section] }}
                                            </p>
                                            <p class="float-right text-right small">
                                                {{ comment.updated_at }}
                                            </p>
                                        </div>

                                        {{ comment.text }}
                                    </b-list-group-item>

                                    <div class="card bg-dark-blue-2 border-none w-100" v-if="comments.length > paginator.perPage">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm"></div>
                                                <div class="col col-sm">
                                                    <b-pagination class="paginator text-center"
                                                                  v-model="paginator.currentPage"
                                                                  :total-rows="comments.length"
                                                                  :per-page="paginator.perPage"
                                                                  aria-controls="commentsPaginator"
                                                                  align="center"
                                                                  limit="3"
                                                                  pills
                                                                  first-text="<<"
                                                                  last-text=">>"
                                                                  prev-text="<"
                                                                  next-text=">"
                                                    />
                                                </div>
                                                <div class="col-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </b-list-group>
                            </b-tab>

                            <b-tab :title="trans('profile.edit')" lazy v-if="editAllowed">
                                <b-form v-on:submit="onSubmitUserInfoData"
                                        id="userDataInfoForm"
                                        ref="userDataInfoForm"
                                        class="mt-2"
                                >
                                    <b-form-group
                                        id="emailFieldset"
                                        :label="trans('profile.email')"
                                        label-for="emailInput"
                                        type="email"
                                        :invalid-feedback="userEmailInvalid"
                                        :state="emailState"
                                    >
                                        <b-form-input
                                            id="emailInput"
                                            type="email"
                                            v-model="user.email"
                                            :state="emailState"
                                            trim
                                            disabled
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="playerSinceFieldset"
                                        :label="trans('profile.owPlayerSince')"
                                        label-for="playerSinceInput"
                                    >
                                        <b-form-select
                                            v-model="user.owPlayerSince"
                                            :options="yearsSelect"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="nicknameFieldset"
                                        :label="trans('profile.multiplayerNick')"
                                        label-for="nicknameInput"
                                        type="text"
                                        :state="nicknameState"
                                    >
                                        <b-form-input
                                            id="emailInput"
                                            type="text"
                                            v-model="user.multiplayerNick"
                                            :state="nicknameState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="streamerFieldset"
                                        :label="trans('profile.favStreamer')"
                                        label-for="streamerInput"
                                        type="text"
                                        :state="streamerState"
                                    >
                                        <b-form-input
                                            id="streamerInput"
                                            type="text"
                                            v-model="user.favStreamer"
                                            :state="streamerState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="localizationFieldset"
                                        :label="trans('profile.localization')"
                                        label-for="localizationInput"
                                        type="text"
                                        :state="localizationState"
                                    >
                                        <b-form-input
                                            id="streamerInput"
                                            type="text"
                                            v-model="user.localization"
                                            :state="localizationState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="websiteFieldset"
                                        :label="trans('profile.website')"
                                        label-for="websiteInput"
                                        type="text"
                                        :state="websiteState"
                                    >
                                        <b-form-input
                                            id="streamerInput"
                                            type="text"
                                            v-model="user.website"
                                            :state="websiteState"
                                            trim
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="aboutFieldset"
                                        :label="trans('profile.about')"
                                        label-for="aboutInput"
                                        type="text"
                                        :state="aboutState"
                                    >
                                        <b-form-textarea
                                            id="aboutInput"
                                            v-model="user.about"
                                            :state="aboutState"
                                            placeholder="..."
                                            rows="5"
                                            max-rows="10"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="submitUserInfoData"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableUserInfoForm ? 'btn-secondary' : 'btn-success'" :disabled="disableUserInfoForm">
                                            {{ trans('profile.save') }}
                                        </b-button>
                                    </b-form-group>
                                </b-form>
                            </b-tab>

                            <b-tab :title="trans('profile.changePassword')" lazy v-if="editAllowed">
                                <b-form v-on:submit="onSubmitUserPassword"
                                        id="userPasswordForm"
                                        ref="userPasswordForm"
                                        class="mt-2"
                                >
                                    <b-form-group
                                        id="passwordFieldset"
                                        :label="trans('profile.password')"
                                        label-for="passwordInput"
                                        type="password"
                                    >
                                        <password
                                            v-model="user.password"
                                            :strength="user.passwordStrength"
                                            id="newPasswordInput"
                                            type="password"
                                            v-on:input="updateNewPassword"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="passwordConfirmFieldset"
                                        :label="trans('profile.passwordConfirm')"
                                        label-for="passwordConfirmInput"
                                        type="password"
                                    >
                                        <b-form-input
                                            id="passwordConfirmInput"
                                            type="password"
                                            v-model="user.confirmPassword"
                                            :state="confirmPasswordState"
                                        />
                                    </b-form-group>

                                    <b-form-group
                                        id="submitUserPassword"
                                    >
                                        <b-button type="submit" size="lg" class="btn-block" v-bind:class="disableChangePassword ? 'btn-secondary' : 'btn-success'" :disabled="disableChangePassword">
                                            {{ trans('profile.save') }}
                                        </b-button>
                                    </b-form-group>
                                </b-form>
                            </b-tab>
                        </b-tabs>
                    </div>

                    <div class="col-lg-2 col-12 col-sm-2 mt-5 mt-sm-0 mt-md-0 mt-lg-0">
                        <b-img v-if="editAllowed" thumbnail fluid lazy :src="user.avatar" alt="User Avatar" v-on:click="onShowAvatar" v-show="user.avatar.length" />
                        <b-img v-else fluid lazy :src="user.avatar" alt="User Avatar" v-show="user.avatar.length" />
                    </div>
                </div>
            </div>

            <modal name="avatarModal" adaptive scrollable height="auto" class="always-top-2">

                <div class="card bg-dark-blue-2 border-card">
                    <div class="card-body">

                        <div class="modal-icon" v-on:click="$modal.hide('avatarModal')">
                            <i class="fa fa-close"/>
                        </div>

                        <div class="modal-title">
                            {{ trans('profile.modal.avatar.title') }}
                        </div>

                        <div class="row">
                            <div class="col-2">
                                <b-img fluid :src="user.avatar" alt="User Avatar" v-show="user.avatar.length" />
                            </div>

                            <div class="col-10">
                                <span>
                                    {{ trans('profile.modal.avatar.text') }}
                                </span>

                                <b-form class="row mt-2 mb-2 mr-sm-2 mb-sm-0"
                                        v-on:submit="onAvatarUrl"
                                        id="avatarForm"
                                >
                                    <div class="col-8">
                                        <b-input
                                            id="avatar-url-input"
                                            :placeholder="trans('profile.modal.avatar.placeholder')"
                                            v-model="user.newAvatar"
                                            :state="newAvatarState"
                                        />
                                    </div>

                                    <div class="col">
                                        <b-button variant="success"
                                                  type="submit"
                                                  :disabled="!newAvatarState"
                                        >
                                            {{ trans('profile.save') }}
                                        </b-button>
                                    </div>
                                </b-form>
                            </div>

                            <div>
                                <div class="row text-center text-lg-left m-2 avatars">
                                    <div v-if="avatars.length"
                                         v-for="avatar in avatars.slice(paginatorAv.perPage * (paginatorAv.currentPage - 1), paginatorAv.perPage * paginatorAv.currentPage)"
                                         :key="avatar.id" class="col-xs-6 col-sm-4 col-md-2 col-lg-2 mb-2">
                                        <b-img thumbnail fluid
                                               :src="avatar.url"
                                               v-on:click="onAvatarChange(avatar)"
                                        />
                                    </div>
                                </div>

                                <div class="card bg-dark-blue-2 border-none w-100" v-if="avatars.length > paginatorAv.perPage">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm"></div>
                                            <div class="col col-sm">
                                                <b-pagination class="paginator text-center"
                                                              v-model="paginatorAv.currentPage"
                                                              :total-rows="avatars.length"
                                                              :per-page="paginatorAv.perPage"
                                                              aria-controls="avatarsPaginator"
                                                              align="center"
                                                              limit="3"
                                                              pills
                                                              first-text="<<"
                                                              last-text=">>"
                                                              prev-text="<"
                                                              next-text=">"
                                                />
                                            </div>
                                            <div class="col-sm"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
        </div>

        <div class="d-flex justify-content-center mt-5 p-5" v-if="!loaded">
            <b-spinner variant="secondary" label="Loading..."/>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                loaded: false,
                id: 0,
                editAllowed: false,
                user: {
                    name: '',
                    email: '',
                    avatar: '',
                    registerDate: '',
                    lastActive: '',
                    owPlayerSince: '',
                    multiplayerNick: '',
                    favStreamer: '',
                    localization: '',
                    website: '',
                    about: '',
                    passwordStrength: 0,
                    password: '',
                    confirmPassword: '',
                    newAvatar: '',
                },
                yearsSelect: [],
                disableUserDataInfoForm: false,
                comments: [],
                sections: [],
                paginator: {
                    perPage: 10,
                    currentPage: 1,
                },
                avatars: [],
                paginatorAv: {
                    perPage: 18,
                    currentPage: 1,
                }
            };
        },
        beforeCreate() {
            this.$store.commit('addToAppQueue');
        },
        beforeMount() {
            this.id = this.$route.params.id;

            if (!this.id) {
                return self.$router.push('/');
            }

            let self = this;

            axios.get('/app/user/get/' + self.id).then(function(response) {
                let data = response.data;

                if (data.success) {
                    if (data.user.length === 0) {
                        return self.$router.push('/');
                    }

                    self.user.name = data.user.name || '';
                    self.user.email = data.user.email || '';
                    self.user.avatar = data.user.avatar || '';
                    self.user.registerDate = data.user.registerDate || '';
                    self.user.points = data.user.points || '';
                    self.user.lastActive = data.user.last_active || '';
                    self.user.owPlayerSince = data.user.player_since || '';
                    self.user.multiplayerNick = data.user.multiplayer_nickname || '';
                    self.user.favStreamer = data.user.fav_streamer || '';
                    self.user.localization = data.user.localization || '';
                    self.user.website = data.user.website || '';
                    self.user.about = data.user.about || '';
                    self.editAllowed = data.user.editAllowed;

                    for (let i = 2001; i <= parseInt(new Date().getFullYear()); i++) {
                        self.yearsSelect.push(i);
                    }

                    if (self.user.owPlayerSince === '') {
                        self.user.owPlayerSince = self.yearsSelect[self.yearsSelect.length-1];
                    }

                    self.loaded = true;
                    self.$store.commit('removeFromAppQueue');
                }
                else {
                    return self.$router.push('/');
                }
            });
        },
        mounted() {
            this.getComments();
            this.getAvatars();
        },
        computed: {
            ...mapGetters({
                userData: 'getUserData'
            }),
            disableUserInfoForm() {
                return (this.nicknameState === false && this.user.multiplayerNick.length > 0) ||
                       (this.websiteState === false && this.user.website.length > 0) ||
                       (this.localizationState === false && this.user.localization.length > 0) ||
                       (this.streamerState === false && this.user.favStreamer.length > 0) ||
                       (this.aboutState === false && this.user.about.length > 0) ||
                       this.disableUserDataInfoForm;
            },
            disableChangePassword() {
                return !(this.user.password === this.user.confirmPassword && this.user.passwordStrength >= 2);
            },
            confirmPasswordState() {
                if (this.user.confirmPassword.length === 0) {
                    return;
                }

                return this.user.password === this.user.confirmPassword;
            },
            newAvatarState() {
                let avatar = this.user.newAvatar;

                if (avatar.length === 0) {
                    return null;
                }

                return this.validateURL(avatar);
            },
            emailState() {
                let email = this.user.email;

                if (email.length === 0) {
                    return null;
                }

                return this.validateEmail(email);
            },
            aboutState() {
                let about = this.user.about;

                if (about.length === 0) {
                    return null;
                }

                return about.length > 0 && about.length < 600;
            },
            websiteState() {
                let website = this.user.website;

                if (website.length === 0) {
                    return null;
                }

                return website.length > 0 && website.length < 120 && this.validateURL(website);
            },
            localizationState() {
                let localization = this.user.localization;

                if (localization.length === 0) {
                    return null;
                }

                return localization.length > 0 && localization.length < 120;
            },
            streamerState() {
                let streamer = this.user.favStreamer;

                if (streamer.length === 0) {
                    return null;
                }

                return streamer.length > 0 && streamer.length < 50;
            },
            nicknameState() {
                let nickname = this.user.multiplayerNick;

                if (nickname.length === 0) {
                    return null;
                }

                return nickname.length > 0 && nickname.length < 50;
            },
            userEmailInvalid() {
                let email = this.user.email;

                if (email.length === 0) {
                    return '';
                }

                if (!this.validateEmail(email)) {
                    return this.trans('auth.emailInvalid');
                }
            },
        },
        methods: {
            onCommentClick: function(comment) {
                let id = comment.id_section;

                if (id === 0)
                    return this.$router.push('/about');
                if (id === 1)
                    return this.$router.push('/videos');
                if (id === 2)
                    return this.$router.push('/gallery');
                if (id === 3)
                    return this.$router.push('/download');
                if (id === 4)
                    return this.$router.push('/news');
            },
            onShowAvatar: function() {
                if (!this.editAllowed) {
                    return;
                }

                this.$modal.show('avatarModal');
            },
            onEditEmail: function() {
                this.$notify({
                    group: 'message',
                    type: 'info',
                    duration: 5000,
                    text: this.trans('profile.notify.email.text'),
                    title: this.trans('profile.notify.email.title'),
                });
            },
            updateNewPassword: function() {
                this.user.passwordStrength = this.updatePasswordStrength(this.user.password);
            },
            onSubmitUserInfoData: function(e) {
                e.preventDefault();
                e.stopPropagation();

                let user = this.user;
                let data = {};

                data.owPlayerSince = user.owPlayerSince;

                if (this.nicknameState) {
                    data.multiplayerNick = user.multiplayerNick;
                }

                if (this.streamerState) {
                    data.favStreamer = user.favStreamer;
                }

                if (this.localizationState) {
                    data.localization = user.localization;
                }

                if (this.websiteState) {
                    data.website = user.website;
                }

                if (this.aboutState) {
                    data.about = user.about;
                }

                if (this.id) {
                    data.id = this.id;
                }

                this.disableUserDataInfoForm = true;
                let self = this;

                axios.post('/app/user/update/', data).then(function(response) {
                    let data = response.data;

                    self.disableUserDataInfoForm = false;

                    if (data.success) {
                        self.$notify({
                            group: 'message',
                            type: 'success',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            },
            onSubmitUserPassword: function(e) {
                e.preventDefault();
                e.stopPropagation();

                let data = {
                    'id' : this.id,
                    'password': this.user.password,
                };

                let self = this;

                axios.post('/app/password/change/', data).then(function(response) {
                    let data = response.data;

                    self.user.confirmPassword = '';

                    if (data.success) {
                        self.user.password = '';
                        self.user.passwordStrength = 0;

                        self.$notify({
                            group: 'message',
                            type: 'success',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            },
            onAvatarChange: function(avatar) {
                if (!avatar) {
                    return;
                }

                let data = {
                    'avatar' : avatar.url,
                };

                let self = this;

                axios.post('/app/avatar/save/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.user.avatar = data.avatar;
                        self.$store.commit('setUserAvatar', data.avatar);
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            },
            onAvatarUrl: function(e) {
                e.preventDefault();
                e.stopPropagation();

                if (!this.newAvatarState) {
                    return;
                }

                let data = {
                    'isUrl' : true,
                    'avatar' : this.user.newAvatar,
                };

                this.user.newAvatar = '';

                let self = this;

                axios.post('/app/avatar/save/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.user.avatar = data.avatar;
                        self.$store.commit('setUserAvatar', data.avatar);
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            },
            getComments: function() {
                let self = this;

                axios.get('/app/comments/?id_user=' + self.id).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.comments = data.comments;
                        self.sections = data.sections;
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            },
            getAvatars: function() {
                let self = this;

                axios.get('/app/avatars/get/').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.avatars = data.avatars;
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 3000,
                            text: data.message,
                            title: data.title,
                        });
                    }
                });
            }
        }
    }
</script>

