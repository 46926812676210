<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body" v-if="loaded">
            <h4 class="card-title">
                {{ trans('issues.title') }} - {{ title }}
            </h4>

            <div class="row mb-3">
                <!-- Nav Top Buttons -->
                <div class="col">
                    <div class="btn-group btn-group-admin">
                        <b-button type="button" class="btn-admin" v-on:click="$router.push('/issues')">
                            <i class="fas fa-angle-left" /> {{ trans('issues.back') }}
                        </b-button>

                        <b-button type="button" class="btn-admin" :disabled="typeof userData.id == 'undefined'" v-on:click="onAddIssue()">
                            <i class="fas fa-plus" /> {{ trans('issues.addIssue') }}
                        </b-button>

                        <b-button type="button" class="btn-admin" :disabled="typeof userData.id == 'undefined'" v-on:click="onShowHistory()">
                            <i class="fas fa-history" /> {{ trans('issues.history') }}
                        </b-button>

                        <b-button type="button" class="btn-admin" v-if="typeof userData.id == 'undefined'" v-on:click="onShowHint()">
                            ?
                        </b-button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-4 mb-md-0 mb-3">
                    <b-tabs align="center" fill>
                        <b-button-group size="sm" class="flex-wrap w-100">
                            <b-button
                                squared
                                class="btn-admin"
                                v-on:click="changeSort()"
                            >
                                {{ trans('issues.sort') }}
                                <i v-bind:class="{'fa-arrow-alt-circle-up' : sortOrder === 0, 'fa-arrow-alt-circle-down' : sortOrder === 1}" class="fas"/>
                            </b-button>

                            <b-dropdown size="sm"
                                        right
                                        squared
                                        dropright
                                        toggle-class="btn-admin"
                                        menu-class="bg-dark-blue-2 border-blue"
                                        :text="filterTitle"
                            >
                                <b-dropdown-item v-on:click="changeFilter(-1)"> <!-- -1 -->
                                    {{ trans('issues.show.all') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(0)"> <!--  0 -->
                                    {{ trans('issues.show.new') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(1)"> <!--  1 -->
                                    {{ trans('issues.show.todo') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(2)"> <!--  2 -->
                                    {{ trans('issues.show.inProgress') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(3)"> <!--  3 -->
                                    {{ trans('issues.show.fixed') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(5)"> <!--  5 -->
                                    {{ trans('issues.show.cantReproduce') }}
                                </b-dropdown-item>
                                <b-dropdown-item v-on:click="changeFilter(-2)" v-if="userData.id > 0"> <!-- -2 -->
                                    {{ trans('issues.show.myIssues') }}
                                </b-dropdown-item>
                            </b-dropdown>
                        </b-button-group>

                        <b-tab v-for="(c, i) in collections" :title="c.name" :key="c.id" v-on:click="onChangeTab(i)">
                            <b-list-group v-if="c.tasks.length > 0">
                                <b-list-group-item v-for="t in (orderTasks(c.tasks)).slice(paginator.perPage * (paginator.currentPage - 1), paginator.perPage * paginator.currentPage)"
                                                   :active="task.id === t.id"
                                                   :key="t.id"
                                                   v-on:click="onClickTask(t.id)"
                                                   class="clickable flex-column align-items-start project"
                                                   v-if="filterId === -1 ||
                                                        (filterId === -2 && t.id_author === userData.id) ||
                                                        (filterId === t.status)
                                                        "
                                >
                                    <div class="d-flex w-100 justify-content-between">
                                        <h6 class="mb-1">
                                            <b-badge variant="primary" v-if="t.status === 0">
                                                {{ trans('issues.show.new') }}
                                            </b-badge>
                                            <b-badge variant="secondary" v-if="t.status === 1">
                                                {{ trans('issues.show.todo') }}
                                            </b-badge>
                                            <b-badge variant="warning" v-if="t.status === 2">
                                                {{ trans('issues.show.inProgress') }}
                                            </b-badge>
                                            <b-badge variant="success" v-if="t.status === 3">
                                                {{ trans('issues.show.fixed') }}
                                            </b-badge>
                                            <b-badge variant="dark" v-if="t.status === 4">
                                                {{ trans('issues.show.notABug') }}
                                            </b-badge>
                                            <b-badge variant="light" v-if="t.status === 5">
                                                {{ trans('issues.show.cantReproduce') }}
                                            </b-badge>
                                        </h6>
                                    </div>

                                    <p class="mb-1">
                                        {{ t.name }}
                                    </p>

                                    <small class="text-secondary">
                                        {{ t.updated_at }}
                                    </small>

                                    <div class="float-right">
                                        <b-img :src="t.flag" width="30" height="16" />
                                    </div>
                                </b-list-group-item>
                            </b-list-group>

                            <div class="row mt-3" v-if="paginator.count > paginator.perPage">
                                <div class="col-sm"></div>
                                <div class="col col-sm">
                                    <b-pagination class="paginator text-center"
                                                  v-model="paginator.currentPage"
                                                  :total-rows="paginator.count"
                                                  :per-page="paginator.perPage"
                                                  aria-controls="paginator"
                                                  align="center"
                                                  limit="3"
                                                  pills
                                                  first-text="<<"
                                                  last-text=">>"
                                                  prev-text="<"
                                                  next-text=">"
                                    />
                                </div>
                                <div class="col-sm"></div>
                            </div>

                            <div class="text-center pt-3" v-if="c.tasks.length === 0">
                                {{ trans('issues.noIssues') }}
                            </div>
                        </b-tab>
                    </b-tabs>


                </div>
                <!-- Task -->
                <div class="col-12 col-sm-8 mb-md-0 mb-3">
                    <div class="row" v-if="task.id > 0">
                        <div class="col-12">
                            <h4>
                                <b-badge variant="primary" v-if="task.status === 0">
                                    {{ trans('issues.show.new') }}
                                </b-badge>
                                <b-badge variant="secondary" v-if="task.status === 1">
                                    {{ trans('issues.show.todo') }}
                                </b-badge>
                                <b-badge variant="warning" v-if="task.status === 2">
                                    {{ trans('issues.show.inProgress') }}
                                </b-badge>
                                <b-badge variant="success" v-if="task.status === 3">
                                    {{ trans('issues.show.fixed') }}
                                </b-badge>
                                <b-badge variant="dark" v-if="task.status === 4">
                                    {{ trans('issues.show.notABug') }}
                                </b-badge>
                                <b-badge variant="light" v-if="task.status === 5">
                                    {{ trans('issues.show.cantReproduce') }}
                                </b-badge>

                                {{ task.name }}
                            </h4>

                            <div class="d-inline">
                                 <b-img rounded
                                        width="40"
                                        height="50"
                                        :src="task.author.avatar"
                                        class="mr-1 float-left"
                                 />

                                 <router-link :to="{ name: 'user', params: { id: task.author.id } }">
                                     {{ task.author.name }}
                                 </router-link>

                                 <span class="float-right">
                                     <a :href="task.git_url_issue" target="_blank" v-if="task.git_url_issue.length">
                                         <i class="icon fab fa-git"/>
                                     </a>
                                     <i class="icon fa fa-edit" v-if="userData.id === task.author.id" v-on:click="onEditIssue(task.id)" />
                                     <i class="icon fa fa-remove" v-if="userData.id === task.author.id" v-on:click="onRemoveIssue(task.id)" />
                                 </span>
                            </div>
                        </div>

                        <div class="w-100">
                            <div class="col-12 mt-2 mb-2" v-html="task.description"/>

                            <div class="col">
                                 <small class="float-right mt-3">
                                     {{ task.updated_at }}
                                 </small>
                            </div>
                        </div>

                        <div class="w-100 task-comments">
                            <div class="w-100 border-card"></div>

                            <div class="w-100 mt-3" v-if="comments.length === 0">
                                <p class="text-center">
                                    {{ trans('comments.noComments') }}
                                </p>
                            </div>

                            <div class="card-body" v-else v-for="comment in comments.slice(this.commentsPaginator.perPage * (this.commentsPaginator.currentPage - 1), commentsPaginator.perPage * commentsPaginator.currentPage)">
                                <div class="row">
                                    <div class="col-10">
                                        <b-img rounded
                                               width="40"
                                               height="50"
                                               :src="comment.author.avatar"
                                               class="mr-1 float-left"
                                        />

                                        <router-link :to="{ name: 'user', params: { id: comment.author.id } }">
                                            {{ comment.author.name }}
                                        </router-link>

                                        <span v-if="comment.author.id === task.author.id" class="text-secondary pl-2">
                                            [{{ trans('issues.author') }}]
                                        </span>

                                        <span class="text-secondary small pl-2">
                                            {{ comment.elapsed }}
                                        </span>
                                    </div>

                                    <div v-if="comment.author.id === userData.id" class="col-2">
                                        <div class="float-right text-right">
                                            <span>
                                                 <i class="icon fa fa-edit" v-on:click="onEditComment(comment)" />
                                                 <i class="icon fa fa-remove" v-on:click="onRemoveComment(comment)" />
                                             </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col">
                                        <p v-html="comment.text" />
                                    </div>
                                </div>
                            </div>

                            <div class="card bg-dark-blue-2 border-none w-100" v-if="comments.length > commentsPaginator.perPage">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm"></div>
                                        <div class="col col-sm">
                                            <b-pagination class="paginator text-center"
                                                          v-model="commentsPaginator.currentPage"
                                                          :total-rows="comments.length"
                                                          :per-page="commentsPaginator.perPage"
                                                          aria-controls="commentsPaginator"
                                                          align="center"
                                                          limit="3"
                                                          pills
                                                          first-text="<<"
                                                          last-text=">>"
                                                          prev-text="<"
                                                          next-text=">"
                                            />
                                        </div>
                                        <div class="col-sm"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="w-100" v-if="userData.isLogged && task.status < 3">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <b-img :src="userData.avatar"
                                                   class="img img-fluid d-none d-md-block img-nohover"
                                                   rounded
                                            />
                                        </div>

                                        <div class="col-md-10">
                                            <a class="float-left" :href="'/user/' + userData.id">
                                                <strong>
                                                    {{ userData.name }}
                                                </strong>
                                            </a>

                                            <small class="text-secondary font-italic ml-3" v-if="comment.isEdit">
                                                {{ trans('comments.editComment') }}
                                            </small>

                                            <span class="float-right">
                                                <i class="icon fa fa-check" v-if="validateComment" v-on:click="onSubmitComment()"/>
                                                <i class="icon fa fa-remove" v-if="comment.isEdit" v-on:click="onCancelComment()"/>
                                            </span>

                                            <b-form-textarea
                                                id="commentTextArea"
                                                v-model="comment.text"
                                                :state="validateComment"
                                                :placeholder="trans('comments.myComment')"
                                                rows="3"
                                                no-resize
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <div class="text-center w-100 mt-5 mb-5" v-else>
            <b-spinner variant="secondary" label="Loading..."/>
        </div>

        <modal name="issueFormModal" scrollable adaptive width="80%" height="auto" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('issueFormModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('issues.modal.issueFormModal.title') }}
                    </div>

                    <b-form v-on:reset="onIssueFormReset()" v-on:submit="onIssueFormSubmit">

                        <b-form-group
                            id="input-group-title"
                            :label="trans('issues.modal.issueFormModal.form.name.label')"
                            label-for="input-title"
                        >
                            <b-form-input
                                id="input-title"
                                v-model="form.name"
                                type="text"
                                :placeholder="trans('issues.modal.issueFormModal.form.name.placeholder')"
                            />
                        </b-form-group>

                        <b-form-group
                            id="input-group-collection"
                            :label="trans('issues.modal.issueFormModal.form.collection.label')"
                            label-for="input-collection"
                        >
                            <b-select v-model="form.collection"
                                      :options="form.collections"
                                      id="input-collection"
                            />
                        </b-form-group>

                        <el-tiptap
                            v-model="form.description"
                            :extensions="extensions"
                        />

                        <b-btn-group class="mt-2">
                            <b-button type="reset" class="btn-admin" :disabled="!(form.name.length > 0 || form.description.length > 0)">
                                <i class="fa fa-trash"/> {{ trans('issues.modal.issueFormModal.form.clear') }}
                            </b-button>

                            <b-button type="button" class="btn-admin" v-on:click="onPreviewIssue()" :disabled="!(form.name.length > 0 && form.description.length > 0)">
                                <i class="fa fa-eye"/> {{ trans('issues.modal.issueFormModal.form.view') }}
                            </b-button>

                            <b-button type="submit" class="btn-admin" :disabled="form.name.length === 0 || form.description.length === 0 || !form.submitAllowed">
                                <i class="fas fa-plus"/> {{ trans('issues.modal.issueFormModal.form.submit') }}
                            </b-button>
                        </b-btn-group>
                    </b-form>

                </div>
            </div>
        </modal>

        <modal name="previewModal" scrollable adaptive height="auto" width="90%" class="always-top-1">
            <div class="card bg-dark-blue-2 border-card">

                <div class="card-body">
                    <div class="modal-icon" v-on:click="$modal.hide('previewModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ preview.title }}
                    </div>

                    <section v-html="preview.content"/>
                </div>

            </div>
        </modal>

        <modal name="issueDeleteModal" scrollable adaptive height="auto" class="always-top-2">

            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('issueDeleteModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('issues.modal.issueDeleteModal.title') }}
                    </div>

                    <p>
                        {{ trans('issues.modal.issueDeleteModal.description') }}
                    </p>

                    <div class="row">
                        <div class="col"></div>

                        <div class="col">
                            <b-button-group class="mt-2">
                                <b-button type="button" variant="danger" size="lg" v-on:click="onRemoveIssueConfirmed()">
                                    {{ trans('issues.modal.issueDeleteModal.yes') }}
                                </b-button>

                                <b-button type="button" size="lg" v-on:click="$modal.hide('issueDeleteModal')">
                                    {{ trans('issues.modal.issueDeleteModal.no') }}
                                </b-button>
                            </b-button-group>
                        </div>

                        <div class="col"></div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="removeCommentConfirmModal" draggable adaptive height="auto" class="always-top-1">
            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('removeCommentConfirmModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('comments.deleteComment') }}
                    </div>

                    <p>
                        {{ trans('comments.deleteConfirmComment') }}
                    </p>

                    <div class="wrapper text-center">
                        <b-button-group size="lg">
                            <b-button variant="danger" v-on:click="onRemoveCommentConfirm()">
                                {{ trans('comments.yes') }}
                            </b-button>
                            <b-button v-on:click="$modal.hide('removeCommentConfirmModal')">
                                {{ trans('comments.no') }}
                            </b-button>
                        </b-button-group>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="historyModal" scrollable adaptive height="auto" class="always-top-1">
            <div class="card bg-dark-blue-2 border-card">

                <div class="card-body" v-if="history.length > 0">
                    <div class="modal-icon" v-on:click="$modal.hide('historyModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <div class="modal-title">
                        {{ trans('issues.history') }}
                    </div>

                    <b-table dark striped hover sort-icon-left
                             :items="history"
                             :fields="historyTable.fields"
                             :per-page="historyTable.perPage"
                             :current-page="historyTable.currentPage"
                             id="historyListTable"
                    />
                </div>

                <div class="card-body text-center" v-else>
                    <div class="modal-icon" v-on:click="$modal.hide('historyModal')">
                        <i class="fa fa-close"/>
                    </div>

                    <span class="h4">
                        {{ trans('issues.noHistory') }}
                    </span>
                </div>

                <div class="card bg-dark-blue-2 border-none w-100" v-if="history.length > historyTable.perPage">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm"></div>
                            <div class="col col-sm">
                                <b-pagination class="paginator text-center"
                                              v-model="historyTable.currentPage"
                                              :total-rows="history.length"
                                              :per-page="historyTable.perPage"
                                              aria-controls="historyPaginator"
                                              align="center"
                                              limit="3"
                                              pills
                                              first-text="<<"
                                              last-text=">>"
                                              prev-text="<"
                                              next-text=">"
                                />
                            </div>
                            <div class="col-sm"></div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    import {
        Doc,
        Text,
        Paragraph,
        Heading,
        Bold,
        Underline,
        Italic,
        Strike,
        // Link,
        // Image,
        Blockquote,
        CodeBlock,
        ListItem,
        BulletList,
        OrderedList,
        TodoItem,
        TodoList,
        TextAlign,
        TextColor,
        TextHighlight,
        Indent,
        LineHeight,
        HardBreak,
        HorizontalRule,
        TrailingNode,
        FormatClear,
        History,
    } from 'element-tiptap';

    import ImageUrl from '../ImageUrl';
    import LinkUrl from '../LinkUrl';
    import EmbedUrl from '../EmbedUrl';

    export default {
        data() {
            return {
                extensions: [
                    new Doc(),
                    new Text(),
                    new Paragraph(),
                    new Heading({level: 5}),
                    new TextColor(),
                    new TextHighlight(),
                    new Bold(),
                    new Underline(),
                    new Italic(),
                    new Strike(),
                    // new Link(),
                    new ImageUrl(),
                    new LinkUrl(),
                    new EmbedUrl(),
                    // new Image(),
                    new Blockquote(),
                    new CodeBlock(),
                    new TextAlign(),
                    new LineHeight(),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new TodoItem(),
                    new TodoList(),
                    new Indent(),
                    new HardBreak(),
                    new HorizontalRule(),
                    new TrailingNode(),
                    new FormatClear(),
                    new History(),
                ],
                loaded: false,
                id: 0,
                title: '',
                project: null,
                collections: null,
                comments: null,
                comment: {
                    id: 0,
                    text: '',
                    isEdit: false,
                    isRemove: false,
                    maxLength: 700,
                },
                task: {
                    id: 0,
                },
                taskLoaded: true,
                filterId: -1,
                sortOrder: 1, // 0 - asc, 1 - desc
                form: {
                    id: 0,
                    name: '',
                    collection: null,
                    collections: {},
                    description: '',
                    submitAllowed: true,
                },
                history: {},
                historyTable: {
                    perPage: 20,
                    currentPage: 1,
                    fields: [
                        {
                            key: 'text',
                            label: 'Log',
                        },
                        {
                            key: 'updated_at',
                            label: '#',
                            sortable: true,
                        }
                    ]
                },
                preview: {
                    title: '',
                    content: '',
                },
                paginator: {
                    perPage: 5,
                    currentPage: 1,
                    count: 0
                },
                commentsPaginator: {
                    perPage: 6,
                    currentPage: 1,
                }
            }
        },
        beforeCreate: function() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            this.id = parseInt(this.$route.params.id);

            this.reloadData();

            if (typeof this.$route.params.id_task !== 'undefined')
                this.onClickTask(parseInt(this.$route.params.id_task));
        },
        computed: {
            ...mapGetters({
                userData: 'getUserData',
                language: 'getLanguageData'
            }),
            validateComment: function() {
                let length = this.comment.text.length;
                let maxLength = this.comment.maxLength;

                if (length === 0)
                    return null;

                return (length > 0 && length < maxLength);
            },
            filterTitle: function() {
                let title = this.trans('issues.show.title');
                let id = this.filterId;

                if (id === -2) {
                    title += ' ' + this.trans('issues.show.myIssues');
                }

                if (id === -1) {
                    title += ' ' + this.trans('issues.show.all');
                }

                if (id === 0) {
                    title += ' ' + this.trans('issues.show.new');
                }

                if (id === 1) {
                    title += ' ' + this.trans('issues.show.todo');
                }

                if (id === 2) {
                    title += ' ' + this.trans('issues.show.inProgress');
                }

                if (id === 3) {
                    title += ' ' + this.trans('issues.show.fixed');
                }

                if (id === 4) {
                    title += ' ' + this.trans('issues.show.notABug');
                }

                if (id === 5) {
                    title += ' ' + this.trans('issues.show.cantReproduce');
                }

                return title;
            }
        },
        methods: {
            onShowHistory: function() {
                let self = this;

                axios.get('/app/issues/history/get/').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.history = data.history;
                        self.$modal.show('historyModal');
                    }
                });
            },
            onSubmitComment: function() {
                let self = this;
                let comment = this.comment;

                if (comment.text.length === 0) {
                    return;
                }

                let data = {
                    'id_task' : self.task.id,
                    'text' : comment.text,
                };

                if (comment.isEdit) {
                    data.id = comment.id;
                }

                axios.post('/app/issues/comments/add/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.commentsReload();
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: data.title,
                            text: data.message,
                        });
                    }
                });
            },
            onEditComment: function(comment) {
                this.comment.text = comment.text;
                this.comment.id = comment.id;
                this.comment.isEdit = true;

                let textarea = this.$el.querySelector("#commentTextArea");
                textarea.scrollIntoView();
            },
            onCancelComment: function() {
                this.comment.text = '';
                this.comment.id = 0;
                this.comment.isEdit = false;
            },
            onRemoveComment: function(comment) {
                this.$modal.show('removeCommentConfirmModal');
                this.comment.id = comment.id;
            },
            onRemoveCommentConfirm: function() {
                let self = this;
                let data = {
                    'id' : self.comment.id,
                };

                if (!data)
                    return;

                axios.post('/app/issues/comments/delete', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.$modal.hide('removeCommentConfirmModal');
                        self.commentsReload();
                    }
                });
            },
            onChangeTab: function(id) {
                this.paginator.currentPage = this.collections[id].paginator.currentPage;
                this.paginator.count = this.collections[id].tasks.length;
            },
            onAddIssue: function() {
                this.form.id = 0;
                this.form.name = '';
                this.form.description = '';

                this.$modal.show('issueFormModal');
            },
            onEditIssue: function(id) {
                let task = this.task;

                if (id !== task.id)
                    return;

                this.form.id = task.id;
                this.form.name = task.name;
                this.form.description = task.description;

                this.$modal.show('issueFormModal');
            },
            onRemoveIssue: function(id) {
                let task = this.task;

                if (id !== task.id)
                    return;

                this.$modal.show('issueDeleteModal');
            },
            onRemoveIssueConfirmed: function() {
                let self = this;

                let data = {
                    'id' : this.task.id,
                    'id_project': this.id,
                };

                axios.post('/app/issues/task/delete/', data).then(function(response) {
                    let data = response.data;

                    self.$modal.hide('issueDeleteModal');

                    if (data.success) {
                        self.task = {
                            id: 0,
                        };
                        self.$notify({
                            group: 'message',
                            type: 'success',
                            title: data.title,
                            text: data.message,
                        });

                        self.reloadData();
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: data.title,
                            text: data.message,
                        });
                    }
                });
            },
            onIssueFormSubmit: function(e) {
                e.preventDefault();
                e.stopPropagation();

                this.form.submitAllowed = false;
                let data = {
                    id: this.form.id,
                    name: this.form.name,
                    description: this.form.description,
                    collection : this.form.collection,
                    id_project: this.id,
                };

                let self = this;

                axios.post('/app/issues/task/set/', data).then(function(response) {
                    let data = response.data;
                    self.form.submitAllowed = true;

                    if (data.success) {
                        self.reloadData();
                        self.$modal.hide('issueFormModal');
                        self.onIssueFormReset();

                        if (self.task.id) {
                            self.onClickTask(self.task.id);
                        }

                        self.$notify({
                            group: 'message',
                            type: 'success',
                            title: data.title,
                            text: data.message,
                        });
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: data.title,
                            text: data.message,
                        });
                    }
                });
            },
            onPreviewIssue: function() {
                this.preview = {
                    title : this.form.name,
                    content : this.form.description,
                };

                this.$modal.show('previewModal');
            },
            onIssueFormReset: function() {
                this.form.name = '';
                this.form.description = '';
            },
            onShowHint: function() {
                this.$notify({
                    group: 'message',
                    type: 'info',
                    title: this.trans('issues.notify.needLogin.title'),
                    text: this.trans('issues.notify.needLogin.text'),
                });
            },
            changeFilter: function (id) {
                this.filterId = id;
            },
            changeDocTitle: function(title) {
                this.$route.meta.title = title;
                document.title += ' - ' + title;
            },
            changeSort: function() {
                this.sortOrder = (this.sortOrder-1) * -1;
            },
            orderTasks: function(tasks) {
                if (this.sortOrder === 1) {
                    return tasks.slice().reverse();
                }

                return tasks;
            },
            onClickTask: function(id) {
                let self = this;

                if (!id) {
                    return;
                }

                self.taskLoaded = false;

                axios.get('/app/issues/task/get/' + id).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.task = data.task;
                        self.comments = data.comments;
                        self.taskLoaded = true;
                    }
                });
            },
            commentsReload: function() {
                let self = this;

                axios.get('/app/issues/comments/get/' + self.task.id).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.comments = data.comments;
                        self.onCancelComment();
                    }
                });
            },
            reloadData: function() {
                let self = this;

                axios.get('/app/issues/projects/get/' + self.id).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.project = data.project;

                        if (data.collections.length) {
                            self.collections = data.collections;
                            self.paginator.count = data.collections[0].tasks.length;

                            for (let i = 0; i < data.collections.length; i++) {
                                self.collections[i].paginator = {
                                    'currentPage' : 1,
                                };

                                self.form.collections[i] = {
                                    'value': data.collections[i].id,
                                    'text': data.collections[i].name
                                };
                            }

                            self.form.collection = data.collections[0].id;
                        }

                        if (self.language.id === 1) {
                            self.title = data.project.name;
                        } else {
                            self.title = data.project.name_pl;
                        }

                        self.changeDocTitle(data.project.name);
                        self.loaded = true;
                        self.$store.commit('removeFromAppQueue');
                    } else {
                        self.$router.push('/issues');
                    }
                });
            }
        }
    }

</script>
