<template>
    <div>

        <modal name="videosModal" draggable adaptive height="auto" :width="768" class="always-top-2">
            <div class="card bg-dark-blue-2 border-card">
                <div class="card-body">

                    <div class="modal-icon" v-on:click="$modal.hide('videosModal')">
                        <i class="fa fa-close"></i>
                    </div>

                    <div class="modal-title">
                        Dodaj filmy do zbioru wyświetlanych
                    </div>

                    <div class="d-flex justify-content-center mt-5" v-if="newVideos.data.length === 0">
                        <b-spinner variant="secondary" label="Loading..."/>
                    </div>

                    <div v-else>
                        <b-table dark striped hover sort-icon-left
                                 :items="newVideos.data"
                                 :fields="newVideos.table.fields"
                                 :per-page="newVideos.table.perPage"
                                 :current-page="newVideos.table.currentPage"
                                 id="newVideosTable"
                                 @row-clicked="onVideoClick"
                        >
                            <template v-slot:cell(index)="data">
                                {{ (newVideos.table.currentPage - 1) * newVideos.table.perPage + data.index + 1 }}
                            </template>

                            <template v-slot:cell(id)="data">
                                {{ data.item.id.videoId }}
                            </template>

                            <template v-slot:cell(title)="data">
                                {{  data.item.snippet.title }}
                            </template>

                            <template v-slot:cell(channel)="data">
                                {{  data.item.snippet.channelTitle }}
                            </template>

                            <template v-slot:cell(thumb)="data">
                                <img v-if="data.item.snippet.thumbnails.default.url" :src="data.item.snippet.thumbnails.default.url" width="90" height="auto" />
                                <span v-else>Brak</span>
                            </template>

                        </b-table>

                        <b-pagination class="paginator"
                                      v-model="newVideos.table.currentPage"
                                      :total-rows="newVideos.data.length"
                                      :per-page="newVideos.table.perPage"
                                      aria-controls="newVideosTable"
                                      align="center"
                                      v-if="newVideos.data.length > newVideos.table.perPage"
                        />
                    </div>

                </div>
            </div>
        </modal>

        <div class="row">
            <!-- Nav Top Buttons -->
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button type="button" class="btn-admin" v-on:click="onShowVideoClick()">
                        Dodaj filmy
                    </b-button>
                </div>
            </div>

            <div class="col-lg-4 col-md-4">
                <b-pagination class="paginator text-center"
                              v-model="paginator.currentPage"
                              :total-rows="videos.data.length"
                              :per-page="paginator.perPage"
                              aria-controls="videosPaginator"
                              align="center"
                />
            </div>
        </div>

        <div class="row text-center text-lg-left mt-2">
            <div v-for="(element, index) in videos.data.slice(this.paginator.perPage * (this.paginator.currentPage - 1), paginator.perPage * paginator.currentPage)" class="col-xs-6 col-sm-4 col-md-2 col-lg-2 mb-2">
                <b-img thumbnail fluid
                       :src="element.snippet.thumbnails.default.url"
                       :id="element.id.videoId"
                       :alt="element.id.videoId"
                       v-on:click="onImageClick(element, index)"
                ></b-img>
                <p class="h6">
                    {{ element.snippet.title }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newVideos: {
                    data: [],
                    table: {
                        perPage: 6,
                        currentPage: 1,
                        fields: [
                            {
                                'key': 'index',
                                'label': '#',
                                'tdClass' : 'clickable',
                            },
                            {
                                'key': 'id',
                                'label': 'ID',
                                'tdClass': 'clickable',
                            },
                            {
                                'key': 'title',
                                'label': 'Nazwa',
                                'tdClass': 'clickable',
                            },
                            {
                                'key': 'channel',
                                'label': 'Kanał',
                                'tdClass': 'clickable',
                            },
                            {
                                'key': 'thumb',
                                'label': 'Miniatura',
                                'tdClass': 'clickable',
                            }
                        ]
                    }
                },
                videos: {
                    data: [],
                },
                paginator: {
                    currentPage: 1,
                    perPage: 24,
                }
            }
        },
        beforeCreate: function() {
            let self = this;

            axios.get('/admin/videos/get/added/').then(function(response) {
                let data = response.data;

                if (data.success) {
                    self.videos.data = data.videos;
                } else {
                    self.$notify({
                        group: 'message',
                        type: 'error',
                        title: 'Wystąpił błąd',
                        text: 'Nie udało się załadować danych.',
                        duration: 3000,
                    });
                }
            });
        },
        methods: {
            reloadVideos: function() {
                let self = this;

                axios.get('/admin/videos/get/').then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.newVideos.data = data.videos;
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: 'Wystąpił błąd',
                            text: 'Nie udało się załadować danych.',
                            duration: 3000,
                        });
                    }
                });
            },
            onShowVideoClick: function() {
                this.$modal.show('videosModal');
                this.reloadVideos();
            },
            onImageClick: function(video, index) {
                if (typeof video.id.videoId === 'undefined')
                    return;

                let self = this;
                let data = {
                    'id' : video.id.videoId,
                };

                axios.post('/admin/videos/delete/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.$delete(self.videos.data, index);
                    }
                });
            },
            onVideoClick: function(video, index) {
                if (typeof video.id.videoId === 'undefined')
                    return;

                let self = this;
                let data = {
                    'id' : video.id.videoId,
                    'video' : video,
                };

                axios.post('/admin/videos/save/', data).then(function(response) {
                    let data = response.data;

                    if (data.success) {
                        self.videos.data.push(video);
                        self.$delete(self.newVideos.data, index + (self.newVideos.table.currentPage - 1) * self.newVideos.table.perPage);
                    }
                });
            },
        }
    }
</script>
