<template>
    <div v-if="loaded">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="btn-group btn-group-admin">
                    <b-button-group>
                        <b-button type="button" class="btn-admin" v-on:click="onClear()">
                            Wyczyść
                        </b-button>
                    </b-button-group>
                </div>
            </div>
        </div>

        <div class="card dark-card p-1" style="max-height: 720px; font-size: 14px;">
            <pre>
                {{ log }}
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                log: '',
                loaded: false,
            }
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onClear: function() {
                let self = this;

                axios.post('/admin/log/clear/').then(function(response) {
                    if (response.data.success) {
                        self.onLoad();
                    }
                });
            },
            onLoad: function() {
                let self = this;

                axios.get('/admin/log/get/').then(function(response) {
                    self.log = response.data.log;
                    self.loaded = true;
                });
            }
        }
    }
</script>
