<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('videos.sosyt') }}
            </h4>

            <div v-if="video.loaded">
                <div class="row mt-3">
                    <div class="col-lg-8">
                        <b-embed
                            type="iframe"
                            aspect="16by9"
                            :src="video.url"
                            allowfullscreen
                        />
                        <h5 class="mt-2 text-truncate">
                            {{ video.title | truncate(70, '...') }}
                        </h5>

                        <div class="row">
                            <div class="col">
                                {{ trans('videos.author') }}: <a :href="video.channel.url">{{ video.channel.name }}</a>
                            </div>

                            <div class="col text-right">
                                {{ trans('videos.views') }}: {{ video.stats.views }}
                            </div>
                        </div>

                        <div class="mt-4" v-if="video.description.length">
                            Opis:
                            <span class="font-italic">
                                "{{ video.description }}"
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-4 mt-3 mt-lg-0">
                        <div v-for="i in videosLimit" class="mb-2 video-url d-inline-flex" v-on:click="onClickVideo(i-1)">
                            <b-img
                                rounded
                                width="120"
                                height="90"
                                :src="videos[i-1].snippet.thumbnails.default.url"
                            />

                            <div class="d-inline-block align-top ml-1">
                                <span class="d-flex h7">{{ videos[i-1].snippet.title | truncate(60, '...') }}</span>
                                <span class="d-flex h8">{{ trans('videos.author') }}: {{ videos[i-1].snippet.channelTitle }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <comments section="videos"></comments>
            </div>

            <div class="d-flex justify-content-center mt-5" v-else>
                <b-spinner variant="secondary" label="Loading..."/>
            </div>

        </div>
    </div>
</template>

<script>
    import Comments from "../components/comments";
    export default {
        components: {Comments},
        data() {
            return {
                video: {
                    url: '',
                    title: '',
                    description: '',
                    loaded: false,
                    channel: {
                        url: '',
                        name: ''
                    },
                    stats: {
                        views: 0,
                    }
                },
                videos: null,
                videosLimit: 6,
                id: '',
                loaded: false,
            }
        },
        beforeCreate: function() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            if (typeof this.$route.params.id !== 'undefined')
               this.loadVideo(this.$route.params.id);
            else
               this.loadVideo(0);
        },
        watch: {
            '$route.params.id': function (id) {
                this.onVideoChanged(id);
            }
        },
        methods: {
            onVideoChanged: function(id) {
                this.loadVideo(id);
            },
            onClickVideo: function(index) {
                let data = this.videos[index].id.videoId;

                if (!data || typeof data === 'undefined')
                    return;

                this.loadVideo(data);
            },
            loadVideo(id) {
                let self = this;

                axios.get('/app/videos/?id=' + id).then(function(response) {
                    let data = response.data;

                    if (!self.loaded) {
                        self.$store.commit('removeFromAppQueue');
                        self.loaded = true;
                    }

                    if (data.success) {
                        self.video.url = 'https://www.youtube.com/embed/' + data.video.id.videoId;
                        self.video.title = data.video.snippet.title;
                        self.video.description = data.video.snippet.description;
                        self.video.channel.url = 'https://www.youtube.com/channel/' + data.video.snippet.channelId;
                        self.video.channel.name = data.video.snippet.channelTitle;
                        self.video.stats.views = data.videoStats.viewCount;
                        self.video.loaded = true;

                        self.id = data.video.id.videoId;
                        history.pushState(null, '', '/videos/' + self.id);

                        self.videos = data.videos;

                        if (self.videos.length < 6)
                            self.videosLimit = self.videos.length;
                        else
                            self.videosLimit = 6;
                    } else {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            title: data.title,
                            text: data.message,
                            duration: 3000,
                        });
                    }
                });
            }
        }
    }
</script>
