<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body" v-if="language.id === 1"> <!-- Eng -->
            <h4 class="card-title">
                {{ trans('layout.faq') }}
            </h4>

            <p>
                Below you can find a list of answers to most asked questions related to the modification.
            </p>

            <div id="faqEN">
                <p class="font-weight-bold h4 h4">
                    I see LUA Error window - what it's that?
                </p>
                <p class="font-italic">
                    Getting the error message "Lua Error" upon starting Sand of Siberia, means your version of Original War does not meet the minimum requirements to run the modification.
                    <span class="d-block">
                        Update Original War to the newest version - sometimes the latest OW patch is not on steam.
                    </span>
                </p>

                <p class="font-weight-bold h4">
                    After installing the patch my game won't start
                </p>
                <p class="font-italic">
                    The most common reason you can't start your game after update is your antivirus software falsely flagging the executable as a virus. To remedy that, please remove the .exe file from quarantine.
                </p>

                <p class="font-weight-bold h4">
                    I don't have full english/czech/french dubbing in the game
                </p>
                <p class="font-italic">
                    All dialogues need to be recorded by someone. In American or Russian campaign, audio files may be loaded from vanilla files. Only custom polish dubbing is available. We don't plan to record english version due to lack of volunteers.
                </p>

                <p class="font-weight-bold h4">
                    I have found a bug while playing. What should i do?
                </p>
                <p class="font-italic">
                    Restart the mission, if the problem persists, contact us via the website contact form. If there is any error log, please include it as well.
                </p>

                <p class="font-weight-bold h4">
                    I want to play on language different than English or Polish. What to do?
                </p>
                <p class="font-italic">
                    Currently, only English and Polish are supported. Playing with different language settings may cause issues. We strongly recommend to use included languages..
                </p>

                <p class="font-weight-bold h4">
                    Some of dialogues are in English, some in Czech/French. How to fix it?
                </p>
                <p class="font-italic">
                    You need to download English Language Pack from this <a href="https://original-war.net/files.php?id=144">url</a> and put downloaded file into main Original War directory.
                </p>

                <p class="font-weight-bold h4">
                    How to port Hack Mod into SoS?
                </p>
                <p class="font-italic">
                    Hack Mod is built into SoS. Check this  <a href="https://www.youtube.com/watch?v=PD04JrDkOK8">video</a> to get more details (eng. subtitles)
                </p>

                <p class="font-weight-bold h4">
                    Is there any way to change interface size?
                </p>
                <p class="font-italic">
                    It's not possible at the moment.
                </p>
            </div>
        </div>

        <article class="card-body" v-else> <!-- Polski -->
            <h4 class="card-title">
                {{ trans('layout.faq') }}
            </h4>

            <p>
                Poniżej znajduje się lista odpowiedzi na najczęściej zadawane pytania związane z modyfikacją lub jej instalacją.
            </p>

            <div id="faqPL">
                <p class="font-weight-bold h4">
                    Na ekranie widzę "LUA ERROR" - co to takiego?
                </p>
                <p class="font-italic">
                    Jest to błąd spowodowany prawdobodobnie przez posiadanie starszej wersji LUA. Jeżeli błąd się powtarza, prosimy o kontakt.
                    <span class="d-block">
                        Upewnij się że posiadasz najnowszą wersję Original War - nie zawsze najnowszy patch jest do pobrania na Steam.
                    </span>
                </p>

                <p class="font-weight-bold h4">
                    Po zainstalowaniu nie mogę odpalić moda.
                </p>
                <p class="font-italic">
                    Najczęstszą przyczyną tego typu błędu jest traktowanie przez oprogramowanie antywirusowe pliku wykonywalnych (.exe) jako potencjalne zagrożenie. Upewnij się żadne pliki powiązane z grą nie znajdują się na kwarantannie.
                </p>

                <p class="font-weight-bold h4">
                    Znalazłem błąd podczasy gry, co robić?
                </p>
                <p class="font-italic">
                    Zrestartuj misję, jeżeli błąd się powtarza, skontaktuj się z nami za pomocą formularza kontaktowego dostępnego w serwisie. Zalecamy dołączyć do wiadomości, log błędu jeśli takowy wystąpił.
                </p>

                <p class="font-weight-bold h4">
                    Niektóre z dialogów są po polsku, a inne po angielsku. Co robić?
                </p>
                <p class="font-italic">
                    Zainstaluj polską paczkę jezykową - <a href="https://original-war.net/files.php?id=143">link</a>.
                </p>

                <p class="font-weight-bold h4">
                    Jak przenieść Hack Moda do Sand of Siberia?
                </p>
                <p class="font-italic">
                    Hack Mod jest wbudowany w modyfikację. Sprawdź ten <a href="https://www.youtube.com/watch?v=PD04JrDkOK8">film</a> aby dowiedzieć się więcej.
                </p>

                <p class="font-weight-bold h4">
                    Jest jakiś sposób na zmianę wielkości interfejsu?
                </p>
                <p class="font-italic">
                    Ta funkcja nie jest obecnie wspierana.
                </p>
            </div>
        </article>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            language: 'getLanguageData'
        }),
    },
}
</script>


