<template>
    <div>
        <swiper></swiper>

        <div v-if="loaded && news.length">
            <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 col-12 mb-lg-0 mb-md-3 mb-3 mt-4 noselect" v-for="n in news">
                    <div class="card bg-dark-blue-3 news-card clickable" v-on:click="onNewsClick(n.id)">
                        <div class="card-header">
                            <span>
                                {{ n.category.name }}
                            </span>
                        </div>
                        <div class="card-body bg-dark-blue-2 news-card-content">
                            <h4 class="card-title">
                                {{ n.name }}
                            </h4>
                            <p class="card-text" v-plaintext>
                                {{ n.text | truncate(90, '...') }}
                            </p>
                            <p class="card-text news-card-info">
                                <small class="text-muted">
                                    {{ trans('layout.phrase.lastUpdate') }} {{ n.elapsed }}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-block w-100 mt-3">
                <div class="text-center">
                    <span class="noselect link h6" v-on:click="redirectToNews()">
                        <i class="fas fa-newspaper"></i> {{ trans('layout.phrase.allNews') }}
                    </span>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-5" v-if="!loaded">
            <b-spinner variant="secondary" label="Loading..."/>
        </div>

        <foot></foot>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loaded: false,
                news: [],
            }
        },
        beforeCreate: function() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            this.getData();
        },
        methods: {
            redirectToNews: function() {
                this.$router.push({ name: "news"});
            },
            getData: function() {
                let self = this;

                axios.get('/app/news/latest/').then(function(response) {
                    let data = response.data;

                    self.news = data.news;
                    self.loaded = true;
                    self.$store.commit('removeFromAppQueue');
                }).catch(function(error) {
                   if (error.response.status === 404) {
                       self.loaded = true;
                   }
                });
            },
            onNewsClick: function(id) {
                this.$router.push({
                    path: '/news/show/' + id,
                });
            }
        }
    }
</script>
