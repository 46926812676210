<template>
    <p class="text-center">{{ errorMsg }}</p>
</template>

<script>
    export default {
        props: ['error'],
        data() {
            return {
                errorMsg: 'Wypełnij formularz i zatwierdź zmiany.',
            }
        },
        watch: {
            error: function(e) {
                if (e == null || e == '') {
                    this.errorMsg = 'Wypełnij formularz i zatwierdź zmiany.';
                } else {
                    this.errorMsg = e;
                }
            }
        }
    }
</script>
