<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('layout.gallery') }}
            </h4>

            <div class="row" v-if="loaded">
                <div class="card bg-dark-blue-2 border-none w-100">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm"></div>
                            <div class="col col-sm">
                                <b-pagination-nav class="paginator text-center"
                                                  v-model="paginator.currentPage"
                                                  :total-rows="images.length"
                                                  :per-page="paginator.perPage"
                                                  v-if="images.length > paginator.perPage"
                                                  aria-controls="imagesPaginator"
                                                  align="center"
                                                  limit="3"
                                                  pills
                                                  first-text="<<"
                                                  last-text=">>"
                                                  prev-text="<"
                                                  next-text=">"
                                                  :link-gen="paginatorGenerateLink"
                                                  :number-of-pages="paginator.pages"
                                                  use-router
                                />
                            </div>
                            <div class="col-sm"></div>
                        </div>
                    </div>
                </div>

                <div class="invisible gallery-hide">
                    <div v-for="(image, imageIndex) in images">
                        <b-img
                            class="gallery-hide"
                            :src="image"
                            :key="imageIndex"
                            rel="preload"
                        />
                    </div>
                </div>

                <VueGallery :images="images" :index="index" @close="index = null"/>

                <div v-for="(image, imageIndex) in images.slice(paginator.perPage * (paginator.currentPage - 1), paginator.perPage * paginator.currentPage)"
                     class="col-lg-3 col-md-4 col-xs-6 mb-4">
                        <b-img thumbnail fluid
                               :src="image"
                               :key="imageIndex"
                               v-on:click="onClickImage(imageIndex)"
                               v-on:load="onLoadImage(image)"
                               v-show="isLoaded(image)"
                        />
                        <div class="d-flex justify-content-center mt-5" v-if="!isLoaded(image)">
                            <b-spinner variant="secondary" label="Loading..."/>
                        </div>
                </div>
            </div>

            <div class="d-flex justify-content-center mt-5" v-if="!loaded">
                <b-spinner variant="secondary" label="Loading..."/>
            </div>

            <comments section="gallery"/>
        </div>
    </div>
</template>

<script>
    import VueGallery from 'vue-gallery';
    import Comments from "../components/comments";
    export default {
        components: {
            Comments,
            VueGallery
        },
        data() {
            return {
                images: [],
                imgLoaded: [],
                index: null,
                loaded: false,
                page: 0,
                paginator: {
                    currentPage: 1,
                    perPage: 12,
                    pages: 0,
                }
            }
        },
        beforeCreate: function() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            this.reloadGallery();
        },
        watch: {
            '$route.params.page': function (page) {
                this.onPageChanged(page);
            }
        },
        methods: {
            isLoaded: function(image) {
                for (let i = 0; i < this.imgLoaded.length; i++) {
                    if (this.imgLoaded[i] === image) {
                        return true;
                    }
                }

                return false;
            },
            onLoadImage: function(image) {
                this.imgLoaded.push(image);
            },
            onPageChanged: function(page) {
                if (page > 0 && page <= this.paginator.pages) {
                    this.paginator.currentPage = page;
                } else {
                    this.paginator.currentPage = 1;
                }
            },
            paginatorGenerateLink: function(pageNum) {
                return {
                    name: 'gallery',
                    params: { page: pageNum }
                }
            },
            reloadGallery: function () {
                let self = this;

                axios.get('/app/gallery').then(function (response) {
                    let data = response.data;

                    if (data) {

                        self.images = data;
                        self.loaded = true;

                        for (let i = 0; i < self.images.length; i += self.paginator.perPage) {
                            self.paginator.pages++;
                        }

                        self.onPageChanged(self.$route.params.page);
                        self.$store.commit('removeFromAppQueue');
                    }
                });
            },
            onClickImage: function(imageIndex) {
                this.index = imageIndex + (this.paginator.perPage * (this.paginator.currentPage - 1));
            }
        }
    }
</script>
