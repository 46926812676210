import { Link as TiptapLink } from 'tiptap-extensions';
import buttonLinkUrl from './components/htmlEditor/buttonLinkUrl';

export default class LinkUrl extends TiptapLink {
    menuBtnView (editorContext) {
        return {
            component: buttonLinkUrl,
            componentProps: {
                editorContext,
            },
        };
    }
}
