<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('layout.news') }}
            </h4>

            <div v-if="showNews">

                <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>

                <article class="d-block ml-3 mr-3">
                    <h3 class="card-title text-center">
                        {{ news.name }}
                    </h3>

                    <div class="text-left text-secondary">
                        <small>
                           {{ trans('news.created_at') }} {{ news.elapsed }}
                        </small>
                    </div>

                    <p v-html="news.text"/>
                </article>


                <b-button variant="outline-light" v-if="news.text.length >= 1400" v-on:click="onPageUp" class="m-2">
                    <i class="fas fa-arrow-up"></i>
                </b-button>

                <comments section="news" :subsection="news.id" />
            </div>

            <div class="text-center w-100 mt-5 mb-5" v-else>
                <b-spinner variant="secondary" label="Loading..."/>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showNews: false,
                news: {},
                breadcrumbs: [
                    {
                        text: this.trans('news.list'),
                        to: '/news',
                    },
                ],

            }
        },
        mounted() {
            if (typeof this.$route.params.id !== 'undefined') {
                this.id = this.$route.params.id;
                this.onLoadNews(this.id);
            }
        },
        methods: {
            onPageUp: function() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            },
            onLoadNews: function(id) {
                if (id <= 0) {
                    return;
                }

                this.showList = false;
                this.loaded = false;

                let self = this;

                axios.get('/app/news/get/' + parseInt(id)).then(function(response) {
                    self.news = response.data.news;
                    self.breadcrumbs[1] = {
                        'text' : self.news.name,
                        'to' : '/news/show/' + self.news.id,
                    };
                    self.showNews = true;
                }).catch(function(error) {
                    if (error.response.status === 404) {
                        self.$notify({
                            group: 'message',
                            type: 'error',
                            duration: 5000,
                            text: '',
                            title: error.response.data.message,
                        });
                    }
                });
            }
        }
    }
</script>
