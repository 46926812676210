<template>
    <div>

        <notifications
            group="message"
            position="top right"
        />

        <nav class="navbar navbar-expand-md sticky-top navbar-dark bg-dark-blue-2">
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar-top">
                <span class="navbar-toggler-icon"></span>
            </button>

            <a class="navbar-brand" href="/">
                Sand of Siberia
            </a>

            <div class="collapse navbar-collapse" id="navbar-top">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <router-link :to="{ name: 'dashboard' }" class="navbar-brand">
                            Admin Panel
                        </router-link>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="container-fluid">
            <div class="row row-offcanvas row-offcanvas-left min-vh-100 bg-dark-blue">
                <div class="col-md-3 col-lg-2 sidebar-offcanvass menu-left sticky-top" id="sidebar" role="navigation">
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'dashboard' }" class="item">
                            Podsumowanie
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'statistics' }" class="item">
                            Statystyki
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'download' }" class="item">
                            Pliki
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'gallery' }" class="item">
                            Galeria
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'videos' }" class="item">
                            Filmy
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'news' }" class="item">
                            Newsy
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'slider' }" class="item">
                            Slider
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'comments' }" class="item">
                            Komentarze
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        Zgłoszenia
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'user' }" class="item">
                            Użytkownicy
                        </router-link>
                    </div>
                    <div class="menu-left-item">
                        <router-link :to="{ name: 'log' }" class="item">
                            Logi
                        </router-link>
                    </div>
                </div>


                <div class="col-md-9 col-lg-10 main">
                    <h1 class="display-1 hidden-xs-down">
                        {{ title }}
                    </h1>

                    <p class="lead">
                        {{ description }}
                    </p>

                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
               title: null,
               description: null
            }
        },
        created: function() {
            this.setTitle();
        },
        updated: function() {
            this.setTitle();
        },
        methods: {
            setTitle: function() {
                let route = this.$route.meta;

                if (route) {
                    this.title = route.title;
                    this.description = route.description;
                }
            }
        }
    }
</script>
