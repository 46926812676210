<template>
    <div class="card bg-dark-blue-2 border-top-card">
        <div class="card-body">
            <h4 class="card-title">
                {{ trans('layout.news') }}
            </h4>

            <div v-if="showList">

                <b-list-group horizontal="md" :v-if="filters.length">
                    <b-list-group-item class="d-flex justify-content-between align-items-center clickable noselect border-bottom-0"
                                       v-for="filtr in filters"
                                       v-if="filtr.count > 0"
                                       :id="filtr.id"
                                       :key="filtr.id"
                                       v-bind:class="filterSelected === filtr.id ? 'active' : ''"
                                       v-on:click="onChangeFilter(filtr.id)"
                    >
                        {{ filtr.name }}

                        <b-badge class="ml-1" variant="primary" pill>
                            {{ filtr.count }}
                        </b-badge>
                    </b-list-group-item>
                </b-list-group>

                <div class="w-100">
                    <div class="card bg-dark-blue-3 news-card-min clickable"
                         v-for="news in newsFilterList.slice(paginator.perPage * (paginator.currentPage - 1), paginator.perPage * paginator.currentPage)"
                         :key="news.id"
                         v-on:click="onShowNews(news.id)"
                    >
                        <div class="card-body bg-dark-blue-2 news-card-content">
                            <h4 class="card-title">
                                {{ news.name }}
                            </h4>
                            <p class="card-text" v-plaintext>
                                {{ news.text | truncate(120, '...') }}
                            </p>
                            <p class="card-text news-card-info">
                                <small class="text-muted">
                                    {{ trans('layout.phrase.lastUpdate') }} {{ news.elapsed }}
                                </small>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card bg-dark-blue-2 border-none w-100">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm"></div>
                            <div class="col col-sm">
                                <b-pagination-nav class="paginator text-center"
                                                  v-model="paginator.currentPage"
                                                  :total-rows="newsFilterList.length"
                                                  :per-page="paginator.perPage"
                                                  v-if="newsFilterList.length > paginator.perPage"
                                                  aria-controls="newsPaginator"
                                                  align="center"
                                                  limit="3"
                                                  pills
                                                  first-text="<<"
                                                  last-text=">>"
                                                  prev-text="<"
                                                  next-text=">"
                                                  :link-gen="paginatorGenerateLink"
                                                  :number-of-pages="paginator.pages"
                                                  use-router
                                />
                            </div>
                            <div class="col-sm"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center w-100 mt-5 mb-5" v-else-if="!loaded">
                <b-spinner variant="secondary" label="Loading..."/>
            </div>

            <div v-else>
                {{ trans('news.empty') }}
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                showList: false,
                loaded: false,
                list: {},
                categories: {},
                filters: {},
                filterSelected: 0,
                page: 0,
                paginator: {
                    currentPage: 1,
                    perPage: 20,
                    pages: 0,
                    init: false,
                }
            }
        },
        beforeCreate() {
            this.$store.commit('addToAppQueue');
        },
        mounted() {
            this.onLoadNewsList();
        },
        computed: {
            newsFilterList: function() {
                return this.filteredList();
            }
        },
        watch: {
            '$route.params.page': function (page) {
                this.onPageChanged(page);
            }
        },
        methods: {
            onPageChanged: function(page) {
                if (page > 0 && page <= this.paginator.pages) {
                    this.paginator.currentPage = page;
                } else {
                    this.paginator.currentPage = 1;
                }
            },
            paginatorGenerateLink: function(pageNum) {
                return {
                    name: 'news',
                    params: { page: pageNum }
                }
            },
            onLoadNewsList: function() {
                let self = this;

                axios.get('/app/news/list/').then(function(response) {
                    self.list = response.data.news;
                    self.onLoadCategories();
                }).catch(function(error) {
                    if (error.response.status === 404) {
                        self.loaded = true;
                    }
                });
            },
            onLoadCategories: function() {
                let self = this;

                axios.get('/app/news/categories/').then(function(response) {
                    self.categories = response.data.categories;

                    self.onSetFilters();

                    self.showList = true;
                    self.loaded = true;
                    self.$store.commit('removeFromAppQueue');
                }).catch(function(error) {
                    if (error.response.status === 404) {
                        self.loaded = true;
                    }
                });
            },
            onSetFilters: function() {
                this.filters = {};

                this.filters[0] = {
                    'id' : 0,
                    'name' : this.trans('news.filter.all'),
                    'count' : this.list.length,
                };

                let categories = this.categories;

                for (let category = 0; category < categories.length; category++) {
                    this.filters[category+1] = {
                        'id' : categories[category].id,
                        'name' : categories[category].name,
                        'count' : this.countNewsByCategory(categories[category].id),
                    };
                }
            },
            countNewsByCategory: function(id) {
                let length = this.list.length;

                if (id <= 0 || length === 0)
                    return 0;

                let counter = 0;

                for (let i = 0; i < length; i++) {
                    if (this.list[i].id_news_category === id) {
                        counter++;
                    }
                }

                return counter;
            },
            onChangeFilter: function(id) {
                this.filterSelected = id;

                if (id > 0) {
                    this.paginator.init = true;
                }
            },
            filteredList: function() {
                let list = this.list;
                let id = this.filterSelected;

                if (id === 0) {
                    this.paginator.pages = this.paginatorPages(list.length, this.paginator.perPage);

                    if (!this.paginator.init) {
                        this.onPageChanged(this.$route.params.page);
                    } else {
                        this.onPageChanged(1);
                    }
                    return list;
                }

                let result = [];

                for (let i = 0; i < list.length; i++) {
                    if (list[i].id_news_category === id) {
                        result.push(list[i]);
                    }
                }

                this.paginator.pages = this.paginatorPages(result.length, this.paginator.perPage);
                this.onPageChanged(1);
                return result;
            },
            paginatorPages: function(amount, perPage) {
                if (amount === 0)
                    return 0;

                let result = 0;

                for (let i = 0; i < amount; i += perPage) {
                    result++;
                }

                return result;
            },
            onShowNews: function(id) {
                if (id <= 0) {
                    return;
                }

                this.$router.push('/news/show/' + id);
            }
        }
    }
</script>
